/* ------------------------------------------------------------------------------
*
*  # Main content layout
*
*  Styles for main structure of content area
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */


// Main wrapper
.page-container {
    position: relative;

    // Desktop view
    @media (min-width: @grid-float-breakpoint) {
        width: 100%;
        display: table;
        table-layout: fixed;

        // Min-height doesn't work in FF, bug. So we set 1px height which is overriden with min-height
        @-moz-document url-prefix() {
            height: 1px;
        }
    }
}

// Main container
.page-content {
    @media (min-width: @grid-float-breakpoint) {
        display: table-row;
    }
}

// Content wrapper
.content-wrapper {
    width: 100%;

    @media (min-width: @grid-float-breakpoint) {
        display: table-cell;
        vertical-align: top;
    }
}

// Main content container
.content {
    padding: 0 @grid-gutter-width (@line-height-computed * 3) @grid-gutter-width;

    // Clear floats
    &:after {
        content: '';
        display: table;
        clear: both;
    }
    
    // Add top padding if no header
    &:first-child {
        padding-top: @line-height-computed;
    }

    // Change bottom spacing if footer is navbar
    body[class*=navbar-bottom] & {
        padding-bottom: @line-height-computed;
    }
}
