/* ------------------------------------------------------------------------------
 *
 *  # Components
 *
 *  Common less file with imports of plugins and pages
 *
 *  Version: 1.3
 *  Latest update: Feb 5, 2016
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Global less file
 *
 *  Common less file with imports of plugins and pages
 *
 *  Version: 1.0
 *  Latest update: Feb 5, 2016
 *
 * ---------------------------------------------------------------------------- */
[v-cloak] {
  display: none;
}
/* ------------------------------------------------------------------------------
*
*  # Utilites
*
*  Additional animations, transitions, transforms and other utilities
*
*  Version: 1.0
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.no-transitions * {
  -webkit-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
.btn,
.navbar-link,
.nav-tabs > li > a,
.nav-tabs > li > a:after,
.nav-pills > li > a,
.nav li > a > .label,
.nav li > a > .badge,
.breadcrumb > li > a,
.breadcrumb-elements > li > a,
.tt-suggestion,
.tokenfield .token,
.selectboxit-btn,
.bootstrap-select .btn-default,
.select2-results__option,
.dropdown-menu > li > a,
.dropdown-menu > li > label,
.wizard .actions a,
.checker span:after,
.choice span:after,
.selectboxit-option-anchor,
.dt-autofill-list ul li,
.dt-autofill-button .btn,
.dataTable .select-checkbox:before,
.dataTable .select-checkbox:after,
.pika-button,
.sp-input,
.navbar-nav > li > a,
.dropdown-content-footer a,
.icons-list > li > a,
.picker__nav--prev,
.picker__nav--next,
.multiselect.btn-default,
.list-group-item,
.pagination > li > a,
.pagination > li > span,
.pager > li > a,
.pager > li > span,
.datepicker-dropdown .day,
a.label,
a.badge,
.ui-datepicker-buttonpane > button,
.ui-button,
.ui-menu-item,
.ui-selectmenu-button,
.ui-datepicker a,
.media-link,
.menu-list li > a,
.plupload_file_action > a,
.dataTables_paginate .paginate_button,
.dataTables_filter input,
.dt-button,
.picker__list-item,
.picker__day,
.picker__footer,
.sp-replacer,
.sp-cancel,
.sp-choose,
.sp-palette-toggle,
.daterangepicker td,
.daterangepicker th,
.noUi-handle,
.fc-button,
.plupload_button,
.picker__footer button,
.picker__list button,
.AnyTime-btn,
.plupload_filelist li,
.password-indicator-group.input-group-addon,
.password-indicator-label-absolute,
.select2-selection--single:not([class*=bg-]),
.select2-selection--multiple:not([class*=bg-]) .select2-selection__choice,
.bootstrap-select.btn-group .dropdown-menu > li > a .check-mark {
  -webkit-transition: all ease-in-out 0.15s;
  -o-transition: all ease-in-out 0.15s;
  transition: all ease-in-out 0.15s;
}
.close,
.tag [data-role="remove"],
.select2-selection__choice__remove {
  -webkit-transition: opacity ease-in-out 0.15s;
  -o-transition: opacity ease-in-out 0.15s;
  transition: opacity ease-in-out 0.15s;
}
.checker span {
  -webkit-transition: border-color ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s;
}
.table tr,
.table th,
.table td,
.htContextMenu td,
.sidebar-user-material-menu > a,
.navbar-nav > .active > a:after,
.ranges ul li {
  -webkit-transition: background-color ease-in-out 0.15s;
  -o-transition: background-color ease-in-out 0.15s;
  transition: background-color ease-in-out 0.15s;
}
.sidebar-user-material-menu > a > .caret {
  -webkit-transition: -webkit-transform ease-in-out 0.15s;
  -o-transition: -webkit-transform ease-in-out 0.15s;
  transition: -webkit-transform ease-in-out 0.15s;
}
/* ------------------------------------------------------------------------------
 *
 *  # Sidebar layouts
 *
 *  Styles for sidebar components, main navigation and sidebar itself
 *
 *  Version: 1.2
 *  Latest update: Nov 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.sidebar {
  background-color: #263238;
  color: #fff;
  position: relative;
  width: 100%;
  display: none;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
@media (min-width: 769px) {
  .sidebar {
    display: table-cell;
    vertical-align: top;
    width: 260px;
  }
}
.sidebar-main {
  z-index: 99;
}
.sidebar-secondary {
  z-index: 98;
}
.sidebar-opposite {
  display: none;
  z-index: 97;
}
@media (min-width: 769px) {
  .sidebar-opposite-visible .sidebar-opposite {
    display: table-cell;
  }
}
.sidebar-default {
  background-color: #fff;
  color: #333333;
}
.sidebar-content {
  position: relative;
  padding-bottom: 8px;
}
.sidebar-all-hidden .sidebar-main,
.sidebar-all-hidden .sidebar-secondary,
.sidebar-main-hidden .sidebar-main,
.sidebar-detached-hidden .sidebar-detached > .sidebar,
.sidebar-secondary-hidden .sidebar-secondary {
  display: none;
}
@media (max-width: 768px) {
  .sidebar-mobile-main .sidebar-main,
  .sidebar-mobile-secondary .sidebar-secondary,
  .sidebar-mobile-opposite .sidebar-opposite,
  .sidebar-mobile-detached .sidebar-detached > .sidebar {
    display: block;
  }
}
.category-title {
  position: relative;
  margin: 0;
  padding: 12px 20px;
  padding-right: 46px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.category-title > span {
  display: block;
  margin-top: 2px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 11px;
  line-height: 1.82;
}
.category-title > i {
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -8px;
}
.category-title .icons-list {
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -8px;
}
.sidebar-default .category-title {
  border-bottom-color: #ddd;
}
.category-content {
  position: relative;
  padding: 20px;
}
.sidebar-user-material .category-content {
  background: url(../images/backgrounds/user_bg.jpg) center center no-repeat;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.sidebar-user-material .navigation-wrapper {
  background-color: rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.sidebar-default .sidebar-user-material .navigation-wrapper {
  background-color: rgba(0, 0, 0, 0.01);
  border-bottom-color: #ddd;
}
.sidebar-user-material .navigation {
  padding: 8px 0;
}
.sidebar-detached .sidebar-user-material:first-child .category-content {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.sidebar-user-material-menu {
  margin: -20px;
  margin-top: 20px;
}
.sidebar-user-material-menu > a {
  padding: 10px 20px;
  display: block;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.1);
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
}
.sidebar-user-material-menu > a:hover,
.sidebar-user-material-menu > a:focus,
.sidebar-user-material-menu > a[aria-expanded="true"] {
  background-color: rgba(0, 0, 0, 0.15);
}
.sidebar-user-material-menu > a > i {
  float: right;
  margin-top: 2px;
}
.sidebar-user-material-menu > a > .caret {
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
}
.sidebar-user-material-menu > a[aria-expanded="true"] > .caret {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sidebar-user-material-content {
  text-align: center;
}
.sidebar-user-material-content > a {
  display: inline-block;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.sidebar-user-material-content > a > img {
  height: 80px;
}
.sidebar-user-material-content > h6 {
  margin-bottom: 0;
}
.sidebar-user-material-content > h6,
.sidebar-user-material-content > span {
  color: #fff;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
}
.sidebar-xs .sidebar-user-material-content {
  display: none;
}
.sidebar-xs .sidebar-user-material > .category-content {
  padding: 0;
}
.sidebar-xs .sidebar-user-material-menu {
  margin: 0;
}
.sidebar-xs .sidebar-user-material-menu > a {
  text-align: center;
  padding: 20px;
  line-height: 1;
}
.sidebar-xs .sidebar-user-material-menu > a > span {
  display: none;
}
.sidebar-xs .sidebar-user-material-menu > a > i {
  float: none;
  margin-top: 0;
}
.navigation {
  margin: 0;
  padding: 10px 0;
  list-style: none;
  position: relative;
}
.sidebar-user + .sidebar-category .navigation {
  padding-top: 0;
}
.navigation .hidden-ul {
  display: none;
}
.navigation li {
  position: relative;
}
.navigation li + li {
  margin-top: 1px;
}
.navigation li + .navigation-header {
  margin-top: 10px;
}
.navigation li a {
  color: rgba(255, 255, 255, 0.75);
  display: block;
  -webkit-transition: background 0.15s linear, color 0.15s linear;
  -o-transition: background 0.15s linear, color 0.15s linear;
  transition: background 0.15s linear, color 0.15s linear;
}
.navigation li a:hover,
.navigation li a:focus {
  background-color: rgba(0, 0, 0, 0.1);
  color: #fff;
}
.navigation li a > i {
  float: left;
  top: 0;
  margin-top: 2px;
  margin-right: 15px;
  -webkit-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}
.navigation li a > i.pull-right {
  margin-right: 0;
  margin-left: 15px;
}
.navigation li.disabled > a,
.navigation li.disabled > a:hover,
.navigation li.disabled > a:focus {
  color: inherit;
  background-color: transparent;
  cursor: not-allowed;
  opacity: 0.3;
  filter: alpha(opacity=30);
}
.navigation li > .has-ul {
  position: relative;
  padding-right: 36px;
}
.navigation li > .has-ul:after {
  content: "\e9c7";
  font-family: "icomoon";
  font-size: 16px;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -8px;
  right: 20px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  -o-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
}
.navigation li.active > .has-ul:after {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.navigation li.navigation-divider {
  margin: 10px 0;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
}
.sidebar-default .navigation li.navigation-divider {
  background-color: #eeeeee;
}
.navigation > li > a {
  padding: 12px 20px;
  min-height: 44px;
  font-weight: 500;
}
.navigation > li.active > a,
.navigation > li.active > a:hover,
.navigation > li.active > a:focus {
  background-color: #304047;
  color: #fff;
}
.navigation > li.active > a > [class*=text-] {
  color: #fff;
}
.navigation > li.active > a .label,
.navigation > li.active > a .badge {
  background-color: transparent;
  border-color: transparent;
  color: #fff;
  -webkit-transition: background-color ease-in-out 0.2s;
  -o-transition: background-color ease-in-out 0.2s;
  transition: background-color ease-in-out 0.2s;
}
.navigation > li ul {
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05);
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05);
}
.navigation > li ul li a {
  padding: 10px 20px;
  padding-left: 51px;
  min-height: 40px;
}
.navigation > li ul .navigation-header {
  padding-left: 51px;
}
.navigation > li > ul > li > ul > li > a,
.navigation > li > ul > li > ul > .navigation-header {
  padding-left: 71px;
}
.navigation > li > ul > li > ul > li > ul > li > a,
.navigation > li > ul > li > ul > li > ul .navigation-header {
  padding-left: 91px;
}
.navigation > li > ul li:first-child {
  padding-top: 10px;
}
.navigation > li > ul li:last-child {
  padding-bottom: 10px;
}
.navigation > li > ul li.active > a,
.navigation > li > ul li.active > a:hover,
.navigation > li > ul li.active > a:focus {
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
}
.navigation .navigation-header {
  min-height: 30px;
  padding: 10px 20px;
  border-bottom: 0;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 1.82;
}
.navigation .navigation-header > span {
  display: block;
  margin-top: 2px;
}
.navigation .navigation-header > i {
  display: none;
}
.navigation .navigation-header > i.pull-right {
  margin-top: 2px;
}
.navigation .navigation-header,
.navigation .navigation-header a {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
}
.navigation .navigation-header a:hover,
.navigation .navigation-header a:focus {
  color: #fff;
}
.navigation .navigation-header a i {
  float: none;
  margin: 0;
}
.sidebar-default .navigation .navigation-header,
.sidebar-default .navigation .navigation-header a {
  color: #999999;
}
.sidebar-default .navigation .navigation-header a:hover,
.sidebar-default .navigation .navigation-header a:focus {
  color: #333333;
}
.navigation.nav > .active > .hidden-ul {
  display: block;
}
@media (max-width: 768px) {
  .navigation.nav > li > .hidden-ul {
    display: block;
  }
}
.navigation .label,
.navigation .badge {
  float: right;
}
.sidebar-default .navigation li > a {
  color: #333333;
}
.sidebar-default .navigation li > a:hover,
.sidebar-default .navigation li > a:focus {
  background-color: #f8f8f8;
}
.sidebar-default .navigation li.active > a,
.sidebar-default .navigation li.active > a:hover,
.sidebar-default .navigation li.active > a:focus {
  background-color: #03A9F5;
  color: #333333;
}
.sidebar-default .navigation li.active > a > [class*=text-] {
  color: #333333;
}
.sidebar-default .navigation li.disabled > a,
.sidebar-default .navigation li.disabled > a:hover,
.sidebar-default .navigation li.disabled > a:focus {
  background-color: transparent;
}
.sidebar-default .navigation li .label-transparent {
  color: #333333;
}
.sidebar-default .navigation > li ul {
  background-color: transparent;
}
.sidebar-default .navigation > li.active > a,
.sidebar-default .navigation > li.active > a:hover,
.sidebar-default .navigation > li.active > a:focus {
  background-color: #03A9F5;
  color: #333333;
}
.sidebar-default .navigation > li.active > a .label-transparent {
  color: #333333;
}
.navigation-icons-right > li > a,
.navigation-icons-right > li > a.has-ul {
  padding-right: 20px;
}
.navigation-icons-right > li > a > i {
  float: right;
  margin-right: 0;
  margin-left: 15px;
}
.navigation-icons-right > li > a.has-ul:after,
.navigation-icons-right > li.active > .has-ul:after {
  content: none;
}
.navigation-bordered > li {
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}
.navigation-bordered > li:last-child {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}
.navigation-bordered > li.navigation-header {
  background-color: rgba(0, 0, 0, 0.1);
}
.navigation-bordered > li + li {
  margin-top: 0;
}
.navigation-bordered > li ul {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.navigation-bordered li + .navigation-header {
  margin-top: 0;
}
.sidebar-default .navigation-bordered > li {
  border-top: 1px solid #eeeeee;
}
.sidebar-default .navigation-bordered > li.navigation-header {
  background-color: #fafafa;
}
.navigation-lg > li > a,
.navigation-lg > li > a > span {
  padding-top: 14px;
  padding-bottom: 14px;
  min-height: 48px;
}
.navigation-lg > li ul li a {
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 40px;
}
.navigation-sm > li > a,
.navigation-sm > li > a > span {
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 40px;
}
.navigation-sm > li ul li a {
  padding-top: 6px;
  padding-bottom: 6px;
  min-height: 32px;
}
.navigation-xs > li > a,
.navigation-xs > li > a > span {
  padding-top: 8px;
  padding-bottom: 8px;
  min-height: 36px;
}
.navigation-xs > li ul li a {
  padding-top: 6px;
  padding-bottom: 6px;
  min-height: 32px;
}
@media (min-width: 769px) {
  .sidebar-fixed .sidebar-content {
    position: fixed;
    width: 260px;
    max-height: 100%;
    overflow: auto;
    top: 50px;
    bottom: 50px;
    margin-bottom: -50px;
  }
  .sidebar-xs .sidebar-fixed.sidebar-main .sidebar-content {
    width: 56px;
  }
  .sidebar-fixed.sidebar-default .sidebar-content {
    width: 260px;
  }
  .sidebar-fixed-expanded .sidebar-fixed.sidebar-main {
    position: fixed;
    display: block;
    height: 100%;
    z-index: 1001;
  }
  .sidebar-fixed-expanded .sidebar-fixed.sidebar-main .sidebar-content {
    position: fixed;
  }
  .sidebar-xs .sidebar-fixed-expanded .sidebar-fixed.sidebar-main {
    width: 260px;
  }
  .sidebar-fixed-expanded .content-wrapper {
    padding-left: 56px;
  }
}
.sidebar-xs-indicator .sidebar-fixed .sidebar-content {
  top: 0!important;
}
@media (min-width: 769px) {
  .has-detached-left .container-detached {
    float: right;
    margin-left: -260px;
    width: 100%;
  }
  .has-detached-left .content-detached {
    margin-left: 280px;
  }
  .has-detached-left .sidebar-detached {
    float: left;
  }
  .has-detached-right .container-detached {
    float: left;
    margin-right: -260px;
    width: 100%;
  }
  .has-detached-right .content-detached {
    margin-right: 280px;
  }
  .has-detached-right .sidebar-detached {
    float: right;
  }
  .has-detached-right .sidebar-detached.affix {
    right: 20px;
  }
  .sidebar-detached-hidden .container-detached {
    float: none;
    margin: 0;
  }
  .sidebar-detached-hidden .content-detached {
    margin: 0;
  }
  .sidebar-detached-hidden .sidebar-detached {
    float: none;
  }
}
.sidebar-detached .navigation.nav > .active > .hidden-ul {
  display: block;
}
@media (max-width: 768px) {
  .sidebar-detached .navigation.nav > li > .hidden-ul {
    display: block;
  }
}
.sidebar-detached.affix {
  position: static;
}
@media (min-width: 769px) {
  .sidebar-detached {
    display: block;
    position: relative;
    margin-bottom: 0;
  }
  .sidebar-detached > .sidebar {
    margin-bottom: 0;
    display: block;
    border-radius: 3px;
  }
  .sidebar-detached.affix {
    position: fixed;
    top: 20px;
    bottom: 20px;
    -webkit-transition: bottom ease-in-out 0.15s;
    -o-transition: bottom ease-in-out 0.15s;
    transition: bottom ease-in-out 0.15s;
  }
  .sidebar-detached.affix > .sidebar {
    max-height: 100%;
    overflow-y: auto;
  }
  .sidebar-detached.fixed-sidebar-space {
    bottom: 80px;
  }
  .navbar-bottom .sidebar-detached.fixed-sidebar-space {
    bottom: 90px;
  }
  .navbar-bottom-xxl .sidebar-detached.fixed-sidebar-space {
    bottom: 122px;
  }
  .navbar-bottom-lg .sidebar-detached.fixed-sidebar-space {
    bottom: 94px;
  }
  .navbar-bottom-sm .sidebar-detached.fixed-sidebar-space {
    bottom: 88px;
  }
  .navbar-bottom-xs .sidebar-detached.fixed-sidebar-space {
    bottom: 86px;
  }
  .navbar-fixed .sidebar-detached {
    top: 90px;
  }
  .navbar-fixed-xxl .sidebar-detached {
    top: 122px;
  }
  .navbar-fixed-lg .sidebar-detached {
    top: 94px;
  }
  .navbar-fixed-sm .sidebar-detached {
    top: 88px;
  }
  .navbar-fixed-xs .sidebar-detached {
    top: 86px;
  }
}
@media (min-width: 769px) {
  .sidebar-separate {
    background-color: transparent;
  }
  .sidebar-separate .sidebar-category {
    background-color: #263238;
    border-radius: 3px;
    margin-bottom: 20px;
  }
  .sidebar-separate.sidebar-default {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .sidebar-separate.sidebar-default .sidebar-category {
    background-color: #fff;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
}
@media (min-width: 769px) {
  .sidebar-xs .sidebar-main {
    width: 56px;
  }
  .sidebar-xs .sidebar-main .sidebar-category {
    display: none;
  }
  .sidebar-xs .sidebar-main .sidebar-category-visible {
    display: block;
  }
  .sidebar-xs .sidebar-main .category-title {
    padding: 0;
  }
  .sidebar-xs .sidebar-main .category-title > i {
    padding: 13px 0;
    float: none;
    display: block;
    top: 0;
  }
  .sidebar-xs .sidebar-main .category-title > span {
    display: none;
  }
  .sidebar-xs .sidebar-main .category-title .icons-list {
    position: static;
    text-align: center;
    margin-top: 0;
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .sidebar-xs .sidebar-main .category-title .icons-list > li {
    display: block;
    margin-left: 0;
  }
  .sidebar-xs .sidebar-main .category-title .icons-list > li + li {
    margin-top: 10px;
  }
  .sidebar-xs .sidebar-main .category-title.h6 .icons-list {
    padding-top: 23.538462px;
    padding-bottom: 23.538462px;
  }
  .sidebar-xs .sidebar-main .category-title.h5 .icons-list {
    padding-top: 25.0769236px;
    padding-bottom: 25.0769236px;
  }
  .sidebar-xs .sidebar-main .navigation > li > a {
    display: block;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }
  .sidebar-xs .sidebar-main .navigation > li > a > span {
    display: none;
    position: absolute;
    top: 0;
    right: -260px;
    background-color: #304047;
    padding: 12px 20px;
    width: 260px;
    text-align: left;
    color: #fff;
    cursor: pointer;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
  .sidebar-xs .sidebar-main .navigation > li > a > span .label,
  .sidebar-xs .sidebar-main .navigation > li > a > span .badge {
    background-color: transparent;
    border-color: transparent;
    color: #fff;
    padding-left: 0;
    padding-right: 0;
  }
  .sidebar-xs .sidebar-main .navigation > li > a > i {
    margin: 2px 0;
    display: block;
    float: none;
  }
  .sidebar-xs .sidebar-main .navigation > li > a.legitRipple {
    overflow: visible;
  }
  .sidebar-xs .sidebar-main .navigation > li > .has-ul:after,
  .sidebar-xs .sidebar-main .navigation > li.active > .has-ul:after {
    content: none;
  }
  .sidebar-xs .sidebar-main .navigation > li .has-ul > span {
    border-radius: 0 3px 0 0;
    cursor: default;
  }
  .sidebar-xs .sidebar-main .navigation > li.active > ul {
    display: none!important;
  }
  .sidebar-xs .sidebar-main .navigation > li.disabled:hover > ul,
  .sidebar-xs .sidebar-main .navigation > li.disabled:hover > a > span {
    display: none!important;
  }
  .sidebar-xs .sidebar-main .navigation > li:hover > ul {
    display: block!important;
  }
  .sidebar-xs .sidebar-main .navigation > li:hover > a > span {
    display: block;
  }
  .sidebar-xs .sidebar-main .navigation > li:hover:not(.active) > a {
    background-color: rgba(0, 0, 0, 0.1);
    color: #fff;
  }
  .sidebar-xs .sidebar-main .navigation > li > ul {
    position: absolute;
    right: -260px;
    top: 44px;
    width: 260px;
    display: none;
    background-color: #263238;
    border-radius: 0 0 3px 0;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
  .sidebar-xs .sidebar-main .navigation > li > ul > li > a {
    padding-left: 20px;
    padding-right: 20px;
  }
  .sidebar-xs .sidebar-main .navigation > li > ul > li > ul > li > a {
    padding-left: 30px;
  }
  .sidebar-xs .sidebar-main .navigation > li > ul > li > ul > li > ul > li > a {
    padding-left: 60px;
  }
  .sidebar-xs .sidebar-main .navigation > .navigation-header {
    padding: 0;
    text-align: center;
  }
  .sidebar-xs .sidebar-main .navigation > .navigation-header > i {
    display: block;
    top: 0;
    padding: 13px 0;
  }
  .sidebar-xs .sidebar-main .navigation > .navigation-header > span {
    display: none;
  }
  .sidebar-xs .sidebar-main .sidebar-user .category-content {
    padding-left: 0;
    padding-right: 0;
  }
  .sidebar-xs .sidebar-main .sidebar-user .media-left,
  .sidebar-xs .sidebar-main .sidebar-user .media-right {
    padding: 0;
    text-align: center;
    display: block;
  }
  .sidebar-xs .sidebar-main .sidebar-user .media-left > img,
  .sidebar-xs .sidebar-main .sidebar-user .media-right > img {
    max-width: 100%;
    height: auto!important;
  }
  .sidebar-xs .sidebar-main .sidebar-user .media-left > .img-sm,
  .sidebar-xs .sidebar-main .sidebar-user .media-right > .img-sm {
    margin-top: 1px;
    margin-bottom: 1px;
  }
  .sidebar-xs .sidebar-main .sidebar-user .media-left > .img-xs,
  .sidebar-xs .sidebar-main .sidebar-user .media-right > .img-xs {
    margin-top: 3px;
    margin-bottom: 3px;
  }
  .sidebar-xs .sidebar-main .sidebar-user .media-body,
  .sidebar-xs .sidebar-main .sidebar-user .media-right {
    display: none;
  }
  .sidebar-xs .sidebar-main .navigation-lg > li > ul {
    top: 48px;
  }
  .sidebar-xs .sidebar-main .navigation-sm > li > ul {
    top: 40px;
  }
  .sidebar-xs .sidebar-main .navigation-xs > li > ul {
    top: 36px;
  }
  .sidebar-xs .sidebar-main.sidebar-default .navigation > li > a > span {
    background-color: #03A9F5;
    color: #333333;
  }
  .sidebar-xs .sidebar-main.sidebar-default .navigation > li > a > span .label,
  .sidebar-xs .sidebar-main.sidebar-default .navigation > li > a > span .badge {
    color: #333333;
  }
  .sidebar-xs .sidebar-main.sidebar-default .navigation > li > ul {
    background-color: #fff;
    border-top: 0;
  }
  .sidebar-xs .sidebar-main.sidebar-default .navigation > li:hover:not(.active) > a {
    background-color: #03A9F5;
    color: #333333;
  }
}
.sidebar .row {
  margin-left: -5px;
  margin-right: -5px;
}
.sidebar .row [class*=col-] {
  padding-left: 5px;
  padding-right: 5px;
}
@media (max-width: 768px) {
  .sidebar .sp-container {
    display: block;
    width: 220px;
    margin-left: auto;
    margin-right: auto;
  }
}
.sidebar .sp-flat .sp-picker-container {
  display: block;
  width: 218px;
}
.sidebar .panel-group .panel {
  border-radius: 0;
  border-width: 0 0 1px 0;
}
.sidebar .panel-group .panel:first-child {
  border-top-width: 1px;
}
.sidebar .panel-group .panel + .panel {
  margin-top: 0;
}
.sidebar .media-list-bordered > li {
  border-top: 0;
  border-bottom: 1px solid #eeeeee;
}
.sidebar:not(.sidebar-default) .media .text-muted,
.sidebar:not(.sidebar-default) .media .media-annotation {
  color: rgba(255, 255, 255, 0.8);
}
.sidebar:not(.sidebar-default) .media .media-left > a,
.sidebar:not(.sidebar-default) .media .media-body > a,
.sidebar:not(.sidebar-default) .media .media-right > a {
  color: #fff;
}
.sidebar:not(.sidebar-default) .media .media-link:hover,
.sidebar:not(.sidebar-default) .media .media-link:focus {
  background-color: rgba(0, 0, 0, 0.1);
}
.sidebar:not(.sidebar-default) .media .media-badge {
  border-color: #263238;
}
.sidebar:not(.sidebar-default) .media-list-bordered > li {
  border-color: rgba(255, 255, 255, 0.1);
}
.sidebar .thumbnail {
  margin-bottom: 10px;
}
.sidebar .thumbnail:last-child {
  margin-bottom: 0;
}
.sidebar .thumbnail .zoom-image i {
  font-size: 16px;
  margin-top: -8px;
  margin-left: -8px;
}
.sidebar .sidebar-category .checkbox,
.sidebar .sidebar-category .radio {
  margin-top: 0;
}
.sidebar .sidebar-category .checkbox:last-child,
.sidebar .sidebar-category .radio:last-child {
  margin-bottom: 0;
}
.sidebar .form-group:last-child {
  margin-bottom: 0;
}
.sidebar .nav-tabs {
  border-width: 0 0 1px 0;
}
@media (min-width: 769px) {
  .sidebar .nav-tabs {
    border-width: 1px;
  }
  .sidebar .nav-tabs > li > a {
    border-bottom-color: rgba(255, 255, 255, 0.1);
    background-color: rgba(0, 0, 0, 0.2);
    color: rgba(255, 255, 255, 0.6);
    border-top: 0;
    border-radius: 0;
  }
  .sidebar .nav-tabs > li > a:hover,
  .sidebar .nav-tabs > li > a:focus {
    color: #fff;
    border-bottom-color: rgba(255, 255, 255, 0.1);
  }
  .sidebar .nav-tabs > .active > a,
  .sidebar .nav-tabs > .active > a:hover,
  .sidebar .nav-tabs > .active > a:focus {
    border-top: 0;
    border-bottom: 0;
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.1);
    color: #fff;
  }
  .sidebar .nav-tabs > .active:first-child > a {
    border-left-color: transparent!important;
  }
  .sidebar .nav-tabs > .active:last-child > a {
    border-right-color: transparent!important;
  }
  .sidebar .nav-tabs > .open > a {
    color: #fff;
  }
  .sidebar .nav-tabs > .open:not(.active) > a {
    background-color: rgba(0, 0, 0, 0.2);
  }
}
@media (min-width: 769px) {
  .sidebar-default .nav-tabs > li > a {
    background-color: #fafafa;
    border-bottom-color: #ddd;
    color: #999999;
  }
  .sidebar-default .nav-tabs > li > a:hover,
  .sidebar-default .nav-tabs > li > a:focus {
    color: #333333;
    border-bottom-color: #ddd;
  }
  .sidebar-default .nav-tabs > .active > a,
  .sidebar-default .nav-tabs > .active > a:hover,
  .sidebar-default .nav-tabs > .active > a:focus {
    border-color: #ddd;
    color: #333333;
  }
  .sidebar-default .nav-tabs > .open > a {
    border-bottom-color: #ddd;
    color: #333333;
  }
  .sidebar-default .nav-tabs > .open:not(.active) > a {
    background-color: #fafafa;
  }
}
@media (min-width: 769px) {
  .sidebar-default .nav-justified > li:first-child .dropdown-menu:not(.dropdown-menu-right) {
    left: -1px;
  }
  .sidebar-default .nav-justified > li:last-child .dropdown-menu-right {
    right: -1px;
  }
}
/* ------------------------------------------------------------------------------
*
*  # Main content layout
*
*  Styles for main structure of content area
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.page-container {
  position: relative;
}
@media (min-width: 769px) {
  .page-container {
    width: 100%;
    display: table;
    table-layout: fixed;
  }
  @-moz-document url-prefix() {
    .page-container {
      height: 1px;
    }
  }
}
@media (min-width: 769px) {
  .page-content {
    display: table-row;
  }
}
.content-wrapper {
  width: 100%;
}
@media (min-width: 769px) {
  .content-wrapper {
    display: table-cell;
    vertical-align: top;
  }
}
.content {
  padding: 0 20px 60px 20px;
}
.content:after {
  content: '';
  display: table;
  clear: both;
}
.content:first-child {
  padding-top: 20px;
}
body[class*=navbar-bottom] .content {
  padding-bottom: 20px;
}
/* ------------------------------------------------------------------------------
*
*  # Boxed layout
*
*  Styles for main structure of content area in boxed layout
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.layout-boxed {
  background: url(../images/backgrounds/boxed_bg.png) repeat;
}
.layout-boxed > .navbar {
  margin-left: auto;
  margin-right: auto;
}
.layout-boxed .page-container {
  background-color: #eeeded;
  margin-left: auto;
  margin-right: auto;
  min-width: 0;
}
@media (min-width: 769px) {
  .layout-boxed .page-container,
  .layout-boxed > .navbar {
    width: 769px;
  }
}
@media (min-width: 1025px) {
  .layout-boxed .page-container,
  .layout-boxed > .navbar {
    width: 1025px;
  }
}
@media (min-width: 1200px) {
  .layout-boxed .page-container,
  .layout-boxed > .navbar {
    width: 1200px;
  }
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .layout-boxed {
    background: url(../images/backgrounds/boxed_bg_retina.png) repeat;
  }
}
/* ------------------------------------------------------------------------------
*
*  # Footer
*
*  Main footer styles, currently 1 version only
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.footer {
  position: absolute;
  bottom: 30px;
}
.footer:not(.navbar-fixed-bottom) {
  z-index: 1000;
}
.footer.navbar {
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 10px;
}
body[class*=navbar-bottom] .footer:not(.navbar) {
  display: none;
}
.footer-boxed {
  left: 0;
  right: 0;
  padding-left: 20px;
  padding-right: 20px;
}
/* ------------------------------------------------------------------------------
*
*  # Utilites
*
*  Additional animations, transitions, transforms and other utilities
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.rotate-45 {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.rotate-90 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.rotate-180 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rotate-45-inverse {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.rotate-90-inverse {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.rotate-180-inverse {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.spinner {
  display: inline-block;
  -webkit-animation: rotation 1s linear infinite;
  -o-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
}
.spinner-reverse {
  display: inline-block;
  -webkit-animation: rotation_reverse 1s linear infinite;
  -o-animation: rotation_reverse 1s linear infinite;
  animation: rotation_reverse 1s linear infinite;
}
@-webkit-keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes rotation {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-ms-keyframes rotation {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
  }
}
@-o-keyframes rotation {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
  }
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotation_reverse {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
  }
}
@-moz-keyframes rotation_reverse {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(-360deg);
  }
}
@-ms-keyframes rotation_reverse {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(-360deg);
  }
}
@-o-keyframes rotation_reverse {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(-360deg);
  }
}
@keyframes rotation_reverse {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes bounceOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* ------------------------------------------------------------------------------
 *
 *  # Pace. Default theme
 *
 *  Minimal preloader theme, used as a default theme
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.pace .pace-progress {
  background: #81C784;
  position: fixed;
  z-index: 2000;
  top: 0;
  width: 100%;
  right: 100%;
  height: 2px;
}
.pace-inactive {
  display: none;
}
/* ------------------------------------------------------------------------------
*
*  # Uniform form inputs plugin
*
*  Styles for uniform.min.js - form components styling
*
*  Version: 1.2
*  Latest update: Aug 10, 2016
*
* ---------------------------------------------------------------------------- */
.checker {
  position: relative;
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
}
.checker,
.checker span,
.checker input {
  width: 18px;
  height: 18px;
}
.checker span {
  color: #455A64;
  border: 2px solid #607D8B;
  display: inline-block;
  text-align: center;
  position: relative;
  border-radius: 2px;
}
.checker span:after {
  content: "\ed6e";
  font-family: 'icomoon';
  font-size: 16px;
  color: inherit;
  line-height: 1;
  position: absolute;
  top: -1px;
  left: -1px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
}
.checker span.checked {
  border-color: transparent;
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
}
.checker span.checked:after {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.checker input[type=checkbox],
.choice input[type=radio] {
  border: none;
  background: none;
  display: -moz-inline-box;
  display: inline-block;
  margin: 0;
  vertical-align: top;
  cursor: pointer;
  position: absolute;
  top: -2px;
  left: -2px;
  z-index: 2;
  opacity: 0;
  filter: alpha(opacity=0);
}
.checkbox .checker,
.checkbox-inline .checker {
  position: absolute;
  top: 1px;
  left: 0;
}
.form-horizontal .checkbox .checker,
.form-horizontal .checkbox-inline .checker {
  top: 10px;
}
.checkbox-right .checker {
  left: auto;
  right: 0;
}
.checker.disabled {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.checker.disabled,
.checker.disabled input[type=checkbox] {
  cursor: not-allowed;
}
.checkbox > label:active .checker.disabled span:after,
.checkbox-inline:active .checker.disabled span:after {
  opacity: 0;
  filter: alpha(opacity=0);
}
.checkbox > label:active .checker.disabled span.checked:after,
.checkbox-inline:active .checker.disabled span.checked:after {
  opacity: 1;
  filter: alpha(opacity=100);
}
.checker[class*=border-] span {
  color: inherit;
}
.checker[class*=border-] span:not(.checked) {
  border-color: inherit;
}
.dropdown-menu > .active:not(.disabled) .checker span,
.dropdown-menu[class*=bg-] .checker span,
.page-header-inverse .form-group > .checkbox .checker span,
.page-header-inverse .form-group > .checkbox-inline .checker span,
.navbar-inverse .navbar-form .form-group > .checkbox .checker span,
.navbar-inverse .navbar-form .form-group > .checkbox-inline .checker span,
.sidebar:not(.sidebar-default) .checkbox .checker span,
.sidebar:not(.sidebar-default) .checkbox-inline .checker span {
  border-color: #fff;
  color: #fff;
}
.dropdown-menu > .active:not(.disabled) .checker span.checked,
.dropdown-menu[class*=bg-] .checker span.checked,
.page-header-inverse .form-group > .checkbox .checker span.checked,
.page-header-inverse .form-group > .checkbox-inline .checker span.checked,
.navbar-inverse .navbar-form .form-group > .checkbox .checker span.checked,
.navbar-inverse .navbar-form .form-group > .checkbox-inline .checker span.checked,
.sidebar:not(.sidebar-default) .checkbox .checker span.checked,
.sidebar:not(.sidebar-default) .checkbox-inline .checker span.checked {
  border-color: transparent;
}
.choice {
  position: relative;
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
  border-radius: 100%;
}
.choice,
.choice span,
.choice input {
  width: 18px;
  height: 18px;
}
.choice span {
  border: 2px solid #607D8B;
  display: -moz-inline-box;
  display: inline-block;
  border-radius: 100%;
  text-align: center;
  position: relative;
}
.choice span:after {
  content: "";
  position: absolute;
  top: 3px;
  left: 3px;
  border: 4px solid;
  border-color: inherit;
  width: 0;
  height: 0;
  border-radius: 100%;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}
.choice span.checked:after {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.radio .choice,
.radio-inline .choice {
  position: absolute;
  top: 1px;
  left: 0;
}
.form-horizontal .radio .choice,
.form-horizontal .radio-inline .choice {
  top: 10px;
}
.radio-right .choice {
  left: auto;
  right: 0;
}
.choice.active span:after,
.radio > label:active .choice span:after,
.radio-inline:active .choice span:after {
  opacity: 0.75;
  filter: alpha(opacity=75);
}
.choice.disabled {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.choice.disabled,
.choice.disabled input[type=radio] {
  cursor: not-allowed;
}
.radio > label:active .choice.disabled span:after,
.radio-inline:active .choice.disabled span:after {
  opacity: 0;
  filter: alpha(opacity=0);
}
.radio > label:active .choice.disabled span.checked:after,
.radio-inline:active .choice.disabled span.checked:after {
  opacity: 1;
  filter: alpha(opacity=100);
}
.choice[class*=border-] span {
  border-color: inherit;
}
.choice[class*=border-] span:after {
  border-color: inherit;
}
.dropdown-menu > .active .choice span,
.dropdown-menu[class*=bg-] .choice span,
.page-header-inverse .form-group > .radio .choice span,
.page-header-inverse .form-group > .radio-inline .choice span,
.navbar-inverse .navbar-form .form-group > .radio .choice span,
.navbar-inverse .navbar-form .form-group > .radio-inline .choice span,
.sidebar:not(.sidebar-default) .radio .choice span,
.sidebar:not(.sidebar-default) .radio-inline .choice span {
  border-color: #fff;
}
.uploader {
  position: relative;
  display: inline-block;
  max-width: 100%;
}
.uploader:after {
  content: '';
  display: table;
  clear: both;
}
.uploader .filename {
  color: #999999;
  padding: 8px 16px;
  cursor: pointer;
  display: block;
  border: 1px solid transparent;
  float: right;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.uploader .action {
  cursor: pointer;
  margin: 0;
  float: left;
}
.uploader .action.btn {
  margin-top: 0;
}
.uploader:hover .action,
.uploader:focus .action {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
}
.uploader:active .action {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
}
.uploader input[type=file] {
  width: 100%;
  margin-top: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 38px;
  border: 0;
  cursor: pointer;
  z-index: 10;
  opacity: 0;
  filter: alpha(opacity=0);
}
.uploader.disabled input[type=file] {
  cursor: not-allowed;
}
.uploader.disabled .action {
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.sidebar .form-group > .uploader {
  display: block;
}
.sidebar .form-group > .uploader .filename {
  float: none;
  padding-left: 0;
  padding-right: 0;
}
.sidebar .form-group > .uploader .action {
  display: block;
}
.page-header-inverse .form-group > .uploader .filename,
.navbar-inverse .navbar-form .form-group > .uploader .filename {
  color: rgba(255, 255, 255, 0.9);
}
.uploader-lg input[type=file],
.uploader-lg .action,
.uploader-lg .filename {
  height: 40px;
}
.uploader-lg .filename {
  padding: 9px 18px;
  font-size: 14px;
  line-height: 1.4285715;
}
.uploader-sm input[type=file],
.uploader-sm .action,
.uploader-sm .filename {
  height: 36px;
}
.uploader-sm .filename {
  padding: 7px 14px;
  font-size: 12px;
  line-height: 1.6666667;
}
.uploader-xs input[type=file],
.uploader-xs .action,
.uploader-xs .filename {
  height: 34px;
}
.uploader-xs .filename {
  padding: 6px 12px;
  font-size: 12px;
  line-height: 1.6666667;
}
/* ------------------------------------------------------------------------------
*
*  # Bootstrap switches
*
*  Styles for switch.min.js - checkbox/radio toggle switches
*
*  Version: 1.1
*  Latest update: Oct 20, 2015
*
* ---------------------------------------------------------------------------- */
.bootstrap-switch {
  display: inline-block;
  cursor: pointer;
  margin-top: -2px;
  margin-right: 8px;
  border-radius: 3px;
  border: 1px solid transparent;
  position: relative;
  text-align: left;
  overflow: hidden;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all ease-in-out 0.05s;
  -o-transition: all ease-in-out 0.05s;
  transition: all ease-in-out 0.05s;
}
.bootstrap-switch .bootstrap-switch-container {
  display: inline-block;
  top: 0;
  border-radius: 3px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.bootstrap-switch.bootstrap-switch-focused {
  outline: 0;
}
.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off,
.bootstrap-switch .bootstrap-switch-label {
  cursor: pointer;
  display: inline-block !important;
  height: 100%;
  padding: 8px 16px;
}
.bootstrap-switch input[type='radio'],
.bootstrap-switch input[type='checkbox'] {
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  filter: alpha(opacity=0);
}
.bootstrap-switch input[type='radio'].form-control,
.bootstrap-switch input[type='checkbox'].form-control {
  height: auto;
}
.bootstrap-switch.bootstrap-switch-disabled,
.bootstrap-switch.bootstrap-switch-readonly,
.bootstrap-switch.bootstrap-switch-indeterminate {
  cursor: default !important;
}
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-label {
  cursor: default !important;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.bootstrap-switch.bootstrap-switch-animate .bootstrap-switch-container {
  -webkit-transition: margin-left 0.2s;
  -o-transition: margin-left 0.2s;
  transition: margin-left 0.2s;
}
.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-on {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}
.bootstrap-switch.bootstrap-switch-inverse .bootstrap-switch-handle-off {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}
.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-inverse.bootstrap-switch-off .bootstrap-switch-label {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-inverse.bootstrap-switch-on .bootstrap-switch-label {
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}
.bootstrap-switch-handle-on,
.bootstrap-switch-handle-off {
  text-align: center;
  z-index: 1;
}
.bootstrap-switch-handle-on.bootstrap-switch-default,
.bootstrap-switch-handle-off.bootstrap-switch-default {
  color: #333333;
  background-color: #eeeeee;
}
.bootstrap-switch-handle-on.bootstrap-switch-primary,
.bootstrap-switch-handle-off.bootstrap-switch-primary {
  color: #fff;
  background-color: #03A9F4;
}
.bootstrap-switch-handle-on.bootstrap-switch-danger,
.bootstrap-switch-handle-off.bootstrap-switch-danger {
  color: #fff;
  background-color: #F44336;
}
.bootstrap-switch-handle-on.bootstrap-switch-success,
.bootstrap-switch-handle-off.bootstrap-switch-success {
  color: #fff;
  background-color: #4CAF50;
}
.bootstrap-switch-handle-on.bootstrap-switch-warning,
.bootstrap-switch-handle-off.bootstrap-switch-warning {
  color: #fff;
  background-color: #FF5722;
}
.bootstrap-switch-handle-on.bootstrap-switch-info,
.bootstrap-switch-handle-off.bootstrap-switch-info {
  color: #fff;
  background-color: #00BCD4;
}
.bootstrap-switch-handle-on {
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}
.bootstrap-switch-handle-off {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}
.bootstrap-switch-label {
  text-align: center;
  z-index: 100;
  color: #333333;
  background-color: #fff;
  position: relative;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
}
.bootstrap-switch-label:hover {
  background-color: #fcfcfc;
}
.bootstrap-switch-label:active {
  background-color: #fafafa;
}
.bootstrap-switch-label:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 3px;
  height: 10px;
  margin-top: -5px;
  margin-left: -1px;
  display: inline-block;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.bootstrap-switch-large .bootstrap-switch-handle-on,
.bootstrap-switch-large .bootstrap-switch-handle-off,
.bootstrap-switch-large .bootstrap-switch-label {
  padding: 9px 18px;
}
.bootstrap-switch-mini .bootstrap-switch-handle-on,
.bootstrap-switch-mini .bootstrap-switch-handle-off,
.bootstrap-switch-mini .bootstrap-switch-label {
  padding: 6px 12px;
}
.bootstrap-switch-small .bootstrap-switch-handle-on,
.bootstrap-switch-small .bootstrap-switch-handle-off,
.bootstrap-switch-small .bootstrap-switch-label {
  padding: 7px 14px;
}
.checkbox-switch > label,
label.checkbox-switch.checkbox-inline {
  padding: 0;
}
.checkbox-switch.checkbox-right .bootstrap-switch {
  margin-right: 0;
  margin-left: 8px;
}
/* ------------------------------------------------------------------------------
*
*  # Select2 selects
*
*  Styles for select2.js - custom select plugin
*
*  Version: 1.3
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.select2-container {
  outline: 0;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
}
.select2-selection--single {
  cursor: pointer;
  outline: 0;
  display: block;
  height: 38px;
  padding: 8px 0;
  line-height: 1.5384616;
  position: relative;
  border: 1px solid transparent;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.select2-selection--single:not([class*=bg-]) {
  border-top-color: transparent;
  border-left-width: 0;
  border-right-width: 0;
}
.select2-selection--single:not([class*=bg-]):not([class*=border-]) {
  border-bottom-color: #ddd;
}
.select2-container--focus .select2-selection--single:not([class*=bg-]):not([class*=border-]),
.select2-container--open .select2-selection--single:not([class*=bg-]):not([class*=border-]) {
  border-bottom-color: #03A9F4;
  -webkit-box-shadow: 0 1px 0 #03A9F4;
  box-shadow: 0 1px 0 #03A9F4;
}
.select2-container--disabled .select2-selection--single:not([class*=bg-]) {
  color: #999999;
  border-bottom-style: dashed;
}
.select2-container--disabled .select2-selection--single:not([class*=bg-]):not([class*=border-]) {
  border-bottom-color: #ddd;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.select2-selection--single .select2-selection__rendered {
  display: block;
  padding-right: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select2-selection--single .select2-selection__rendered > i {
  margin-right: 16px;
}
.select2-selection--single .select2-selection__clear {
  position: relative;
  cursor: pointer;
  float: right;
  font-size: 0;
  line-height: 1;
  margin-top: 2px;
  margin-left: 5px;
  opacity: 0.75;
  filter: alpha(opacity=75);
}
.select2-selection--single .select2-selection__clear:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.select2-selection--single .select2-selection__clear:after {
  content: '\ed6b';
  font-family: 'icomoon';
  display: inline-block;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.select2-selection--single .select2-selection__placeholder {
  color: #999999;
}
.select2-selection--single .select2-selection__arrow:after {
  content: '\e9c5';
  font-family: 'Icomoon';
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -8px;
  font-size: 16px;
  line-height: 1;
  color: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.select2-container--open .select2-selection--single .select2-selection__arrow:after {
  content: '\e9c6';
}
.select2-selection--single .select2-selection__arrow b {
  display: none;
}
.select2-container--disabled .select2-selection--single {
  cursor: not-allowed;
}
.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}
.navbar-inverse .navbar-form .select2-selection--single:not([class*=bg-]):not([class*=border-]),
.page-header-inverse .select2-selection--single:not([class*=bg-]):not([class*=border-]) {
  border-bottom-color: rgba(255, 255, 255, 0.3);
}
.navbar-inverse .navbar-form .select2-selection--single .select2-selection__placeholder,
.page-header-inverse .select2-selection--single .select2-selection__placeholder {
  color: #fff;
}
.navbar-inverse .navbar-form .select2-container--focus .select2-selection--single:not([class*=bg-]):not([class*=border-]),
.page-header-inverse .select2-container--focus .select2-selection--single:not([class*=bg-]):not([class*=border-]),
.navbar-inverse .navbar-form .select2-container--open .select2-selection--single:not([class*=bg-]):not([class*=border-]),
.page-header-inverse .select2-container--open .select2-selection--single:not([class*=bg-]):not([class*=border-]) {
  border-bottom-color: #fff;
  -webkit-box-shadow: 0 1px 0 #fff;
  box-shadow: 0 1px 0 #fff;
}
.select2-selection--multiple {
  display: block;
  border: 1px solid transparent;
  cursor: text;
  outline: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.select2-selection--multiple:not([class*=bg-]):not([class*=border-]) {
  border-color: transparent;
}
.select2-selection--multiple:not([class*=bg-])[class*=border-] {
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}
.select2-selection--multiple:not([class*=bg-])[class*=border-] .select2-selection__rendered {
  padding-bottom: 8px;
}
.select2-selection--multiple:not([class*=bg-]) .select2-selection__rendered {
  padding: 0;
}
.select2-selection--multiple:not([class*=bg-]) .select2-selection__choice {
  background-color: #eee;
  color: #333333;
  margin-top: 3px;
  margin-bottom: 3px;
  border-radius: 100px;
}
.select2-selection--multiple:not([class*=bg-]) .select2-selection__choice:hover,
.select2-container--disabled .select2-selection--multiple:not([class*=bg-]) .select2-selection__choice:hover,
.select2-selection--multiple:not([class*=bg-]) .select2-selection__choice:focus,
.select2-container--disabled .select2-selection--multiple:not([class*=bg-]) .select2-selection__choice:focus {
  background-color: #eee;
  color: #333333;
}
.select2-selection--multiple:not([class*=bg-]) .select2-selection__choice:hover,
.select2-selection--multiple:not([class*=bg-]) .select2-selection__choice:focus {
  background-color: #29B6F6;
  color: #fff;
}
.select2-selection--multiple:not([class*=bg-]) .select2-search--inline .select2-search__field {
  margin-top: 3px;
  margin-bottom: 3px;
}
.select2-selection--multiple:not([class*=bg-]) .select2-search--inline:first-child .select2-search__field {
  margin-left: 0;
}
.select2-selection--multiple .select2-selection__rendered {
  overflow: hidden;
  list-style: none;
  margin: 0;
  padding: 0 6px 6px 6px;
  width: 100%;
}
.select2-container--disabled .select2-selection--multiple[class*=bg-] .select2-selection__choice {
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.select2-container--disabled .select2-selection--multiple,
.select2-container--disabled .select2-selection--multiple .select2-selection__choice,
.select2-container--disabled .select2-selection--multiple .select2-search__field {
  cursor: not-allowed;
}
.select2-selection--multiple .select2-selection__choice {
  background-color: #eee;
  color: #fff;
  border-radius: 3px;
  cursor: default;
  float: left;
  margin-right: 6px;
  margin-top: 6px;
  padding: 8px 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.select2-selection--multiple .select2-selection__choice > i {
  margin-right: 10px;
}
.select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
  cursor: pointer;
  float: right;
  font-size: 0;
  margin-top: 4.5px;
  line-height: 1;
  margin-left: 8px;
  opacity: 0.75;
  filter: alpha(opacity=75);
}
.select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove:after {
  content: '\ed6a';
  font-family: 'icomoon';
  display: block;
  font-size: 11px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.select2-container--disabled .select2-selection--multiple .select2-selection__choice {
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.select2-container--disabled .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
  display: none;
}
.select2-selection--multiple .select2-search--inline {
  float: left;
}
.select2-selection--multiple .select2-search--inline .select2-search__field {
  font-size: 100%;
  margin-top: 6px;
  padding: 8px 0;
  background-color: transparent;
  border: 0;
  outline: 0;
  margin-left: 6px;
  -webkit-appearance: textfield;
}
.select2-selection--multiple .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.select2-dropdown {
  background-color: #fff;
  color: #333333;
  border-radius: 3px;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 9999;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.select2-results {
  display: block;
}
.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}
.select2-results > .select2-results__options {
  padding-bottom: 8px;
  max-height: 250px;
  overflow-y: auto;
}
.select2-search--hide + .select2-results > .select2-results__options {
  padding-top: 8px;
}
.select2-results:first-child > .select2-results__options {
  padding-top: 8px;
}
.select2-results__option {
  padding: 8px 16px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.select2-results__option + .select2-results__option {
  margin-top: 1px;
}
.select2-results__option i {
  margin-right: 16px;
}
.select2-results__option i.icon-undefined {
  display: none;
}
.select2-results__option[role=group] {
  padding: 0;
}
.select2-results__option.select2-results__option--highlighted {
  background-color: #03A9F5;
}
.select2-results__option[aria-disabled=true] {
  color: #999999;
  cursor: not-allowed;
}
.select2-dropdown[class*=bg-] .select2-results__option[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.6);
}
.select2-results__option[aria-selected=true] {
  background-color: #03A9F4;
  color: #fff;
}
.select2-results__options--nested > .select2-results__option {
  padding-left: 32px;
  padding-right: 32px;
}
.select2-results__group {
  display: block;
  padding: 8px 16px;
  font-size: 11px;
  line-height: 1.82;
  text-transform: uppercase;
  cursor: default;
  margin-top: 8px;
  margin-bottom: 8px;
}
.select2-results__option:first-child > .select2-results__group {
  margin-top: 0;
}
.select2-results__message {
  color: #999999;
  cursor: default;
}
.select2-dropdown[class*=bg-] .select2-results__message {
  color: rgba(255, 255, 255, 0.75);
}
.select2-results__option.loading-results {
  padding-top: 0;
}
.select2-results__option.loading-results + .select2-results__option {
  margin-top: 8px;
}
.select2-results__option--load-more {
  text-align: center;
  margin-top: 8px;
  cursor: default;
}
.select2-container--open .select2-dropdown {
  left: 0;
}
.select2-container--open .select2-dropdown--above {
  border-bottom-width: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-container--open .select2-dropdown--above[class*=bg-] {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.select2-container--open .select2-dropdown--above:not([class*=bg-]) {
  top: -3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.select2-container--open.select2-container--above .select2-selection--single,
.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.select2-container--open .select2-dropdown--below {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container--open .select2-dropdown--below[class*=bg-] {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.select2-container--open .select2-dropdown--below:not([class*=bg-]) {
  top: 3px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.select2-search--dropdown {
  display: block;
  position: relative;
  padding: 16px;
  padding-left: 48px;
}
.select2-search--dropdown:after {
  content: '\e98e';
  font-family: 'icomoon';
  position: absolute;
  top: 50%;
  left: 16px;
  color: inherit;
  display: block;
  font-size: 14px;
  margin-top: -7px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.select2-search--dropdown + .select2-results .select2-results__message:first-child {
  padding-top: 0;
}
.select2-search--dropdown .select2-search__field {
  background-color: transparent;
  padding: 8px 0;
  border-radius: 3px;
  border: 1px solid #ddd;
  border-color: transparent transparent #ddd;
  outline: 0;
  width: 100%;
}
.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.select2-search--dropdown.select2-search--hide {
  display: none;
}
.select-lg.select2-selection--single {
  height: 40px;
  padding: 9px 0;
  font-size: 14px;
}
.select-lg.select2-selection--single .select2-selection__rendered {
  padding-right: 40px;
}
.select-lg.select2-selection--multiple .select2-selection__choice {
  padding: 9px 18px;
  font-size: 14px;
}
.select-lg.select2-selection--multiple .select2-search--inline .select2-search__field {
  padding: 9px 0;
  font-size: 14px;
}
.select-sm.select2-selection--single {
  height: 36px;
  padding: 7px 0;
  font-size: 12px;
  line-height: 1.6666667;
}
.select-sm.select2-selection--single .select2-selection__rendered {
  padding-right: 32px;
}
.select-sm.select2-selection--multiple .select2-selection__choice {
  padding: 7px 14px;
  font-size: 12px;
  line-height: 1.6666667;
}
.select-sm.select2-selection--multiple .select2-search--inline .select2-search__field {
  padding: 7px 0;
}
.select-xs.select2-selection--single {
  height: 34px;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.6666667;
}
.select-xs.select2-selection--single .select2-selection__rendered {
  padding-right: 28px;
}
.select-xs.select2-selection--multiple .select2-selection__choice {
  padding: 6px 12px;
  font-size: 12px;
  line-height: 1.6666667;
}
.select-xs.select2-selection--multiple .select2-search--inline .select2-search__field {
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.6666667;
}
.select2-selection--single[class*=bg-],
.select2-selection--multiple[class*=bg-] {
  border-radius: 3px;
}
.select2-container--disabled .select2-selection--single[class*=bg-],
.select2-container--disabled .select2-selection--multiple[class*=bg-] {
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.select2-selection--single[class*=bg-] .select2-selection__placeholder {
  color: #fff;
}
.select2-selection--single[class*=bg-] .select2-selection__rendered {
  padding-left: 16px;
  padding-right: 40px;
}
.select2-container--disabled .select2-selection--single[class*=bg-] .select2-selection__rendered {
  color: rgba(255, 255, 255, 0.75);
}
.select2-selection--single[class*=bg-] .select2-selection__arrow:after {
  right: 16px;
}
.select2-selection--multiple[class*=bg-] .select2-selection__choice {
  background-color: rgba(0, 0, 0, 0.25);
}
.select2-selection--multiple[class*=bg-] .select2-search--inline .select2-search__field::-moz-placeholder {
  color: #fff;
  opacity: 1;
}
.select2-selection--multiple[class*=bg-] .select2-search--inline .select2-search__field:-ms-input-placeholder {
  color: #fff;
}
.select2-selection--multiple[class*=bg-] .select2-search--inline .select2-search__field::-webkit-input-placeholder {
  color: #fff;
}
.select2-dropdown[class*=bg-] .select2-search--dropdown .select2-search__field {
  background-color: transparent;
  border-bottom-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}
.select2-dropdown[class*=bg-] .select2-results__option[aria-selected=true] {
  background-color: rgba(0, 0, 0, 0.2);
}
.select2-dropdown[class*=bg-] .select2-results__option--highlighted {
  background-color: rgba(0, 0, 0, 0.1);
}
.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  z-index: 99;
  background-color: #fff;
  opacity: 0;
  filter: alpha(opacity=0);
}
.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: fixed !important;
  width: 1px !important;
}
.select2-result-repository {
  padding-top: 8px;
  padding-bottom: 8px;
}
.select2-result-repository__avatar {
  float: left;
  width: 60px;
  margin-right: 15px;
}
.select2-result-repository__avatar img {
  width: 100%;
  height: auto;
  border-radius: 100px;
}
.select2-result-repository__meta {
  margin-left: 70px;
}
.select2-result-repository__title {
  font-weight: 500;
  word-wrap: break-word;
  margin-bottom: 2px;
}
.select2-result-repository__forks,
.select2-result-repository__stargazers,
.select2-result-repository__watchers {
  display: inline-block;
  font-size: 12px;
}
.select2-result-repository__description {
  font-size: 12px;
}
.select2-result-repository__forks,
.select2-result-repository__stargazers {
  margin-right: 15px;
}
/* ------------------------------------------------------------------------------
*
*  # Bootstrap multiselect
*
*  Styles for multiselect.js - custom multiple select plugin
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.multiselect {
  width: 100%;
  min-width: 100%;
  text-align: left;
  padding-right: 32px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.multiselect.btn-default {
  border-width: 1px 0;
  padding: 8px 0;
  padding-right: 24px;
  border-radius: 0;
  text-transform: none;
  font-weight: 400;
}
.multiselect.btn-default,
.multiselect.btn-default:hover,
.multiselect.btn-default:focus,
.multiselect.btn-default:active,
.btn-group.open > .multiselect.btn-default.dropdown-toggle {
  border-color: transparent transparent #ddd;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.multiselect.btn-default:focus,
.btn-group.open > .multiselect.btn-default.dropdown-toggle {
  border-bottom-color: #03A9F4;
  -webkit-box-shadow: 0 1px 0 #03A9F4;
  box-shadow: 0 1px 0 #03A9F4;
}
.multiselect.btn-default.disabled {
  border-bottom-style: dashed;
}
.multiselect.btn-default .caret {
  right: 0;
}
.multiselect .caret {
  position: absolute;
  top: 50%;
  right: 16px;
  margin-top: -8px;
  width: 16px;
  text-align: right;
}
.multiselect.btn-lg {
  padding-right: 36px;
}
.multiselect.btn-lg.btn-default {
  padding-right: 27px;
}
.multiselect.btn-sm {
  padding-right: 28px;
}
.multiselect.btn-sm.btn-default {
  padding-right: 21px;
}
.multiselect.btn-xs {
  padding-right: 24px;
}
.multiselect.btn-xs.btn-default {
  padding-right: 18px;
}
.page-header-inverse .form-group .multiselect.btn-default,
.navbar-inverse .navbar-form .multiselect.btn-default {
  border-bottom-color: rgba(255, 255, 255, 0.3);
  color: #fff;
}
.page-header-inverse .form-group .btn-group.open > .multiselect.btn-default,
.navbar-inverse .navbar-form .btn-group.open > .multiselect.btn-default,
.page-header-inverse .form-group .multiselect.btn-default:focus,
.navbar-inverse .navbar-form .multiselect.btn-default:focus {
  color: #fff;
  border-bottom-color: #fff;
  -webkit-box-shadow: 0 1px 0 #fff;
  box-shadow: 0 1px 0 #fff;
}
.multiselect-container {
  min-width: 180px;
  max-height: 250px;
  overflow-y: auto;
}
.multiselect-container > li {
  padding: 0;
}
.multiselect-container > li > a {
  padding: 0;
}
.multiselect-container > li > a > label {
  display: block!important;
  margin: 0;
  height: 100%;
  cursor: pointer;
  padding: 9px 16px;
  padding-left: 44px;
}
.navbar-form .multiselect-container > li > a > label {
  display: block;
}
.multiselect-container > li > a .checker,
.multiselect-container > li > a .choice {
  top: 50%;
  margin-top: -9px;
  left: 16px;
}
.multiselect-container > li > a.multiselect-all label {
  font-weight: 500;
}
.multiselect-container > li.disabled > a {
  background-color: transparent;
}
.multiselect-container > li.disabled > a > label {
  cursor: not-allowed;
}
.multiselect-container > li.multiselect-group > label,
.multiselect-container > li.multiselect-group.active > label {
  margin: 0;
  padding: 9px 15px;
  font-size: 11px;
  line-height: 1.82;
  color: #999999;
  text-transform: uppercase;
  margin-top: 16px;
  background-color: transparent;
  cursor: default;
}
.multiselect-container > li.multiselect-group.disabled {
  color: #999999;
}
.multiselect-container > li.multiselect-group:first-child > label,
.multiselect-container > li.multiselect-group:first-child.active > label {
  margin-top: 8px;
}
.multiselect-container > li.multiselect-group.multiselect-group-clickable > label {
  cursor: pointer;
}
.multiselect-container > li.multiselect-group.disabled label,
.multiselect-container > li.multiselect-group.disabled:hover label,
.multiselect-container > li.multiselect-group.disabled:focus label {
  background-color: transparent;
  cursor: not-allowed;
}
.multi-select-full > .btn-group {
  width: 100%;
}
.multi-select-full > .btn-group .multiselect-container {
  width: inherit;
}
.multi-select-full + .input-group-btn {
  padding-left: 16px;
}
.multi-select-fixed > .btn-group > .btn {
  width: 250px;
}
.multi-select-auto > .btn-group > .btn {
  width: auto;
}
li.multiselect-filter {
  position: relative;
  padding: 8px 16px;
  padding-left: 44px;
  margin-bottom: 8px;
}
li.multiselect-filter i {
  font-size: 14px;
  position: absolute;
  left: 16px;
  top: 50%;
  margin-top: -7px;
}
.btn-group-multiselect {
  width: 100%;
}
.btn-group-multiselect .btn {
  float: none;
}
.btn-group-multiselect .multiselect {
  min-width: 0;
}
/* ------------------------------------------------------------------------------
*
*  # Bootstrap select
*
*  Styles for bootstrap_select.js - custom select boxes plugin
*
*  Version: 1.2
*  Latest update: Aug 20, 2016
*
* ---------------------------------------------------------------------------- */
.bootstrap-select {
  width: 100%;
}
.bootstrap-select > .btn {
  width: 100%;
  padding-right: 43px;
}
.bootstrap-select .btn-default {
  padding: 8px 0;
  padding-right: 24px;
  background-color: transparent;
  border: 1px solid transparent;
  border-width: 1px 0;
  font-weight: 400;
  text-transform: none;
  border-bottom-color: #ddd;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.bootstrap-select .btn-default:focus,
.bootstrap-select .btn-default.focus,
.bootstrap-select .btn-default:active,
.bootstrap-select .btn-default.active {
  background-color: transparent;
  border-bottom-color: #03A9F4;
  -webkit-box-shadow: 0 1px 0 #03A9F4;
  box-shadow: 0 1px 0 #03A9F4;
}
.bootstrap-select .btn-default.disabled,
.bootstrap-select .btn-default[disabled],
fieldset[disabled] .bootstrap-select .btn-default {
  border-bottom-style: dashed;
  background-color: transparent;
  border-bottom-color: #ddd;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.bootstrap-select.btn-group.open > .btn-default.dropdown-toggle {
  border-bottom-color: #03A9F4;
  background-color: transparent;
  -webkit-box-shadow: 0 1px 0 #03A9F4;
  box-shadow: 0 1px 0 #03A9F4;
}
.bootstrap-select.form-control {
  padding: 0;
  border: 0;
}
.bootstrap-select.form-control:not([class*="col-"]) {
  width: 100%;
}
.bootstrap-select .popover-title {
  font-size: 11px;
  line-height: 1.82;
  margin-top: 8px;
  margin-bottom: 1px;
  font-weight: 400;
  padding: 8px 16px;
  color: #999999;
}
.bootstrap-select[class*=border-] .btn,
.bootstrap-select[class*=border-] .dropdown-menu {
  border-color: inherit;
}
.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 220px;
}
.bootstrap-select.fit-width {
  width: auto !important;
}
.bootstrap-select.btn-group.bs-container {
  position: absolute;
}
.bootstrap-select.btn-group:not(.input-group-btn),
.bootstrap-select.btn-group[class*="col-"] {
  float: none;
  display: inline-block;
  margin-left: 0;
}
.bootstrap-select.btn-group.dropdown-menu-right,
.bootstrap-select.btn-group[class*="col-"].dropdown-menu-right {
  float: right;
}
.bootstrap-select.btn-group > .disabled {
  cursor: not-allowed;
}
.bootstrap-select.btn-group > .disabled:focus {
  outline: 0;
}
.bootstrap-select.btn-group .btn .filter-option {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  text-align: left;
  text-overflow: ellipsis;
}
.bootstrap-select.btn-group .btn .filter-option > i,
.bootstrap-select.btn-group .dropdown-menu > li > a [class*=icon-] {
  margin-right: 16px;
  float: left;
  margin-top: 2px;
  top: 0;
}
.bootstrap-select.btn-group .btn .caret {
  position: absolute;
  top: 50%;
  width: 16px;
  text-align: right;
  right: 16px;
  margin-top: -8px;
}
.bootstrap-select.btn-group[class*="col-"] .btn {
  width: 100%;
}
.bootstrap-select.btn-group .btn-default .caret {
  right: 0;
}
.bootstrap-select.btn-group .btn-lg:not(.btn-default) {
  padding-right: 43px;
}
.bootstrap-select.btn-group .btn-lg:not(.btn-default) > .caret {
  right: 18px;
}
.bootstrap-select.btn-group .btn-sm:not(.btn-default) {
  padding-right: 37px;
}
.bootstrap-select.btn-group .btn-sm:not(.btn-default) > .caret {
  right: 14px;
}
.bootstrap-select.btn-group .btn-xs:not(.btn-default) {
  padding-right: 34px;
}
.bootstrap-select.btn-group .btn-xs:not(.btn-default) > .caret {
  right: 12px;
}
.bootstrap-select.btn-group .dropdown-menu {
  min-width: 100%;
}
.bootstrap-select.btn-group .dropdown-menu > li > a .check-mark {
  position: absolute;
  top: 50%;
  margin-top: -8px;
  right: 16px;
  margin-right: 0;
  color: transparent;
}
.bootstrap-select.btn-group .dropdown-menu > li > a .text {
  display: inline-block;
}
.bootstrap-select.btn-group .dropdown-menu > li > a > .label,
.bootstrap-select.btn-group .dropdown-menu > li > a > .badge {
  float: none;
}
.bootstrap-select.btn-group .dropdown-menu > li > a.special-class,
.bootstrap-select.btn-group .dropdown-menu > li > a.special-class:hover {
  font-weight: 700;
  color: #fff;
  background-color: #F44336;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}
.bootstrap-select.btn-group .dropdown-menu > li.disabled a {
  cursor: not-allowed;
}
.bootstrap-select.btn-group .dropdown-menu > li small {
  padding-left: 10px;
}
.bootstrap-select.btn-group .dropdown-menu > .selected > a {
  background-color: #03A9F4;
  color: #fff;
}
.bootstrap-select.btn-group .dropdown-menu > .selected > a small {
  color: #fff;
}
.bootstrap-select.btn-group .dropdown-menu.inner {
  position: static;
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.bootstrap-select.btn-group .dropdown-menu .notify {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 8px 16px;
  background-color: #f8f8f8;
  border-top: 1px solid transparent;
  pointer-events: none;
}
.bootstrap-select.btn-group .dropdown-header ~ li > a {
  padding-left: 32px;
}
.bootstrap-select.btn-group .no-results {
  padding: 8px 16px;
  background: #f8f8f8;
  margin-bottom: -8px;
  border-top: 1px solid transparent;
}
.bootstrap-select.btn-group.fit-width .btn .filter-option,
.bootstrap-select.btn-group.fit-width .btn .caret {
  position: static;
}
.bootstrap-select.btn-group.show-tick .dropdown-menu > li a .text {
  margin-right: 48px;
}
.bootstrap-select.btn-group.show-tick .dropdown-menu > li.selected a .check-mark {
  color: #fff;
}
.input-group .bootstrap-select > .btn {
  border-radius: 0;
}
.error .bootstrap-select .btn {
  border: 1px solid #F44336;
}
.control-group.error .bootstrap-select .dropdown-toggle {
  border-color: #F44336;
}
.bs-searchbox,
.bs-actionsbox {
  padding: 8px 16px;
  margin-bottom: 8px;
}
.bs-searchbox {
  position: relative;
  padding-left: 48px;
}
.bs-searchbox:after {
  content: '\e98e';
  font-family: 'icomoon';
  position: absolute;
  top: 50%;
  left: 16px;
  color: inherit;
  display: block;
  font-size: 14px;
  margin-top: -11px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.bs-actionsbox {
  float: left;
  width: 100%;
  position: relative;
}
.bs-actionsbox .btn-group button {
  width: 50%;
}
.bs-searchbox + .bs-actionsbox {
  padding: 0 16px 8px;
}
.mobile-device {
  position: absolute;
  top: 0;
  left: 0;
  display: block !important;
  width: 100%;
  height: 100% !important;
  opacity: 0;
}
select.bs-select-hidden,
select.selectpicker {
  display: none !important;
}
.bootstrap-select > select {
  position: absolute!important;
  bottom: 0;
  left: 50%;
  display: block!important;
  width: .5px!important;
  height: 100%!important;
  padding: 0!important;
  opacity: 0!important;
  border: none;
}
/* ------------------------------------------------------------------------------
 *
 *  # Twiter Typeahead
 *
 *  Styles for typeahead.bundle.min.js - input suggestion engine
 *
 *  Version: 1.3
 *  Latest update: Jul 4, 2016
 *
 * ---------------------------------------------------------------------------- */
.twitter-typeahead {
  width: 100%;
}
.typeahead,
.tt-query,
.tt-hint {
  outline: 0;
}
.tt-hint {
  color: #999;
}
.tt-menu {
  width: 100%;
  margin-top: 1px;
  min-width: 180px;
  padding: 8px 0;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 3px;
  max-height: 300px;
  overflow-y: auto;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.typeahead-scrollable .tt-menu {
  max-height: 250px;
}
.typeahead-rtl .tt-menu {
  text-align: right;
}
.tt-suggestion {
  padding: 8px 15px;
  cursor: pointer;
}
.tt-suggestion:hover,
.tt-suggestion:focus,
.tt-suggestion.tt-cursor {
  background-color: #03A9F5;
}
.tt-dataset-group .tt-suggestion {
  padding-left: 32px;
  padding-right: 32px;
}
.tt-heading {
  font-size: 11px;
  line-height: 1.82;
  padding: 8px 15px;
  text-transform: uppercase;
  display: block;
  font-weight: 700;
  margin-top: 2px;
  margin-bottom: 2px;
}
.typeahead-template .empty-message {
  padding: 8px 15px;
  text-align: center;
}
/* ------------------------------------------------------------------------------
 *
 *  # Form Validation
 *
 *  jQuery plugin for simple clientside form validation
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.validation-error-label,
.validation-valid-label {
  margin-top: 8px;
  margin-bottom: 8px;
  display: block;
  color: #F44336;
  position: relative;
  padding-left: 26px;
}
.validation-valid-label {
  color: #4CAF50;
}
.validation-error-label:before,
.validation-valid-label:before {
  font-family: 'icomoon';
  position: absolute;
  top: 2px;
  left: 0;
  display: inline-block;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}
.validation-error-label:empty,
.validation-valid-label:empty {
  display: none;
}
.validation-error-label:before {
  content: '\ed63';
}
.validation-valid-label:before {
  content: '\ee73';
}
/* ------------------------------------------------------------------------------
*
*  # Bootstrap tags input
*
*  Styles for tagsinput.js - tags input for Bootstrap
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.bootstrap-tagsinput {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.bootstrap-tagsinput input {
  direction: ltr;
  border: 0;
  outline: 0;
  background-color: transparent;
  padding: 7px 0;
  margin-top: 3px;
  margin-bottom: 3px;
  width: auto !important;
  min-width: 100px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.bootstrap-tagsinput input:focus {
  border: none;
  box-shadow: none;
}
.bootstrap-tagsinput .twitter-typeahead {
  width: auto;
}
.bootstrap-tagsinput .tt-dropdown-menu {
  margin-top: 5px;
  min-width: 200px;
}
.bootstrap-tagsinput .tag {
  margin-top: 3px;
  margin-bottom: 3px;
  margin-right: 6px;
  border: 0;
  border-radius: 100px;
  padding: 7px 14px;
  padding-right: 33px;
  float: left;
  font-size: 12px;
  line-height: 1.6666667;
  font-weight: 400;
  text-transform: none;
  position: relative;
}
.bootstrap-tagsinput .tag:not([class*=bg-]) {
  background-color: #29B6F6;
  color: #fff;
}
.bootstrap-tagsinput .tag [data-role="remove"] {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 14px;
  line-height: 1;
  margin-top: -5.5px;
  opacity: 0.7;
  filter: alpha(opacity=70);
}
.bootstrap-tagsinput .tag [data-role="remove"]:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.bootstrap-tagsinput .tag [data-role="remove"]:after {
  content: '\ed6a';
  font-family: 'icomoon';
  display: block;
  font-size: 11px;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* ------------------------------------------------------------------------------
*
*  # Steps wizard
*
*  An all-in-one wizard plugin that is extremely flexible, compact and feature-rich
*
*  Version: 1.2
*  Latest update: Aug 10, 2016
*
* ---------------------------------------------------------------------------- */
.wizard {
  width: 100%;
}
.wizard > .steps .current-info,
.wizard > .content > .title {
  position: absolute;
  left: -99999px;
}
.wizard > .content {
  position: relative;
  width: auto;
  padding: 0;
}
.wizard > .content > .body {
  padding: 0 20px;
}
.wizard > .content > iframe {
  border: 0 none;
  width: 100%;
  height: 100%;
}
.wizard > .steps {
  position: relative;
  display: block;
  width: 100%;
}
.wizard > .steps > ul {
  display: table;
  width: 100%;
  table-layout: fixed;
  margin: 0;
  padding: 0;
  list-style: none;
}
.wizard > .steps > ul > li {
  display: table-cell;
  width: auto;
  vertical-align: top;
  text-align: center;
  position: relative;
}
.wizard > .steps > ul > li a {
  position: relative;
  padding-top: 48px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: block;
}
.wizard > .steps > ul > li:before,
.wizard > .steps > ul > li:after {
  content: '';
  display: block;
  position: absolute;
  top: 43px;
  width: 50%;
  height: 2px;
  background-color: #00BCD4;
  z-index: 9;
}
.wizard > .steps > ul > li:before {
  left: 0;
}
.wizard > .steps > ul > li:after {
  right: 0;
}
.wizard > .steps > ul > li:first-child:before,
.wizard > .steps > ul > li:last-child:after {
  content: none;
}
.wizard > .steps > ul > li.current:after,
.wizard > .steps > ul > li.current ~ li:before,
.wizard > .steps > ul > li.current ~ li:after {
  background-color: #eeeeee;
}
.wizard > .steps > ul > li.current > a {
  color: #333333;
  cursor: default;
}
.wizard > .steps > ul > li.current .number {
  font-size: 0;
  border-color: #00BCD4;
  background-color: #fff;
  color: #00BCD4;
}
.wizard > .steps > ul > li.current .number:after {
  content: '\e913';
  font-family: 'icomoon';
  display: inline-block;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 34px;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
.wizard > .steps > ul > li.disabled a,
.wizard > .steps > ul > li.disabled a:hover,
.wizard > .steps > ul > li.disabled a:focus {
  color: #999999;
  cursor: default;
}
.wizard > .steps > ul > li.done a,
.wizard > .steps > ul > li.done a:hover,
.wizard > .steps > ul > li.done a:focus {
  color: #999999;
}
.wizard > .steps > ul > li.done .number {
  font-size: 0;
  background-color: #00BCD4;
  border-color: #00BCD4;
  color: #fff;
}
.wizard > .steps > ul > li.done .number:after {
  content: '\ed6f';
  font-family: 'icomoon';
  display: inline-block;
  font-size: 16px;
  line-height: 34px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
.wizard > .steps > ul > li.error .number {
  border-color: #F44336;
  color: #F44336;
}
@media (max-width: 768px) {
  .wizard > .steps > ul {
    margin-bottom: 20px;
  }
  .wizard > .steps > ul > li {
    display: block;
    float: left;
    width: 50%;
  }
  .wizard > .steps > ul > li > a {
    margin-bottom: 0;
  }
  .wizard > .steps > ul > li:first-child:before,
  .wizard > .steps > ul > li:last-child:after {
    content: '';
  }
  .wizard > .steps > ul > li:last-child:after {
    background-color: #00BCD4;
  }
}
@media (max-width: 480px) {
  .wizard > .steps > ul > li {
    width: 100%;
  }
  .wizard > .steps > ul > li.current:after {
    background-color: #00BCD4;
  }
}
.wizard > .steps .number {
  background-color: #fff;
  color: #ccc;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -19px;
  width: 38px;
  height: 38px;
  border: 2px solid #eeeeee;
  font-size: 14px;
  border-radius: 50%;
  z-index: 10;
  line-height: 34px;
  text-align: center;
}
.panel-flat > .wizard > .steps > ul {
  border-top: 1px solid #ddd;
}
.wizard > .actions {
  position: relative;
  display: block;
  text-align: right;
  padding: 20px;
  padding-top: 0;
}
.wizard > .actions > ul {
  float: right;
  list-style: none;
  padding: 0;
  margin: 0;
}
.wizard > .actions > ul:after {
  content: '';
  display: table;
  clear: both;
}
.wizard > .actions > ul > li {
  float: left;
}
.wizard > .actions > ul > li + li {
  margin-left: 10px;
}
.wizard > .actions > ul > li > a {
  background: #03A9F4;
  color: #fff;
  display: block;
  padding: 8px 16px;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: 500;
  border: 1px solid transparent;
}
.wizard > .actions > ul > li > a:hover,
.wizard > .actions > ul > li > a:focus {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
}
.wizard > .actions > ul > li > a:active {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
}
.wizard > .actions > ul > li > a[href="#previous"] {
  background-color: #03A9F5;
  color: #333;
  border: 1px solid transparent;
}
.wizard > .actions > ul > li > a[href="#previous"]:hover,
.wizard > .actions > ul > li > a[href="#previous"]:focus {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.02) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.02) inset;
}
.wizard > .actions > ul > li > a[href="#previous"]:active {
  -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.04) inset;
  box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.04) inset;
}
.wizard > .actions > ul > li.disabled > a,
.wizard > .actions > ul > li.disabled > a:hover,
.wizard > .actions > ul > li.disabled > a:focus {
  color: #999999;
}
.wizard > .actions > ul > li.disabled > a[href="#previous"],
.wizard > .actions > ul > li.disabled > a[href="#previous"]:hover,
.wizard > .actions > ul > li.disabled > a[href="#previous"]:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
/* ------------------------------------------------------------------------------
*
*  # Pick-a-date - Picker base
*
*  The mobile-friendly, responsive, and lightweight jQuery date & time input picker
*
*  Version: 1.2
*  Latest update: Jul 5, 2016
*
* ---------------------------------------------------------------------------- */
.picker {
  width: 100%;
  text-align: left;
  position: absolute;
  top: 100%;
  margin-top: -1px;
  z-index: 990;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.picker__input {
  cursor: default;
}
.picker__holder {
  width: 100%;
  overflow-y: auto;
  position: absolute;
  display: none;
  background-color: #fff;
  border-top-width: 0;
  border-bottom-width: 0;
  max-width: 290px;
  max-height: 0;
  outline: 0;
  -webkit-overflow-scrolling: touch;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.picker--opened .picker__holder {
  max-height: 480px;
  border-top-width: 1px;
  border-bottom-width: 1px;
  display: block;
}
/* ------------------------------------------------------------------------------
*
*  # Pick-a-date - Date picker
*
*  The mobile-friendly, responsive, and lightweight jQuery date & time input picker
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.picker__box {
  padding: 16px;
}
.picker__header {
  text-align: center;
  position: relative;
  font-size: 17px;
  line-height: 1;
  padding-top: 15px;
  padding-bottom: 15px;
}
.picker__month,
.picker__year {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}
.picker__year {
  color: #999999;
  font-size: 13px;
}
.picker__select--month,
.picker__select--year {
  border-color: #ddd;
  height: 34px;
  font-size: 13px;
  line-height: 1.6666667;
  margin-left: 5px;
  margin-right: 5px;
  outline: 0;
}
.picker__select--month {
  width: 35%;
}
.picker__select--year {
  width: 35%;
}
.picker__nav--prev,
.picker__nav--next {
  position: absolute;
  padding: 8px;
  top: 50%;
  margin-top: -16px;
  border-radius: 2px;
  line-height: 1;
}
.picker__nav--prev:before,
.picker__nav--next:before {
  font-family: 'icomoon';
  display: block;
  font-size: 16px;
  width: 16px;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.picker__nav--prev:hover,
.picker__nav--next:hover {
  cursor: pointer;
  background-color: #03A9F5;
}
.picker__nav--prev {
  left: 0;
}
.picker__nav--prev:before {
  content: '\e9c8';
}
.picker__nav--next {
  right: 0;
}
.picker__nav--next:before {
  content: '\e9cb';
}
.picker__nav--disabled,
.picker__nav--disabled:hover,
.picker__nav--disabled:before,
.picker__nav--disabled:before:hover {
  cursor: default;
  background: none;
  border-right-color: #03A9F5;
  border-left-color: #03A9F5;
}
.picker__table {
  text-align: center;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  font-size: inherit;
  width: 100%;
  margin-bottom: 16px;
}
.picker__table td {
  margin: 0;
  padding: 0;
}
.picker__weekday {
  width: 14.285714286%;
  font-size: 12px;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 16px;
  color: #999999;
  font-weight: 400;
}
.picker__day {
  padding: 8px;
}
.picker__day--today {
  position: relative;
  background-color: #03A9F5;
}
.picker__day--today:before {
  content: "";
  position: absolute;
  top: 2px;
  right: 2px;
  width: 0;
  height: 0;
  border-top: 6px solid #03A9F4;
  border-left: 6px solid transparent;
}
.picker__day--outfocus {
  color: #ccc;
}
.picker__day--infocus:hover,
.picker__day--outfocus:hover {
  cursor: pointer;
  color: #333333;
  background-color: #03A9F5;
}
.picker__day--highlighted:before {
  border-top-color: #fff;
}
.picker__day--highlighted,
.picker__day--selected {
  border-radius: 3px;
}
.picker__day--highlighted,
.picker__day--highlighted:hover,
.picker--focused .picker__day--highlighted {
  cursor: pointer;
  color: #fff;
  background-color: #29B6F6;
}
.picker__day--selected,
.picker__day--selected:hover,
.picker--focused .picker__day--selected {
  background-color: #03A9F4;
  color: #fff;
}
.picker__day--disabled,
.picker__day--disabled:hover {
  background: #fafafa;
  color: #999999;
  cursor: default;
}
.picker__day--disabled:before {
  border-top-color: #999;
}
.picker__day--highlighted .picker__day--disabled,
.picker__day--highlighted .picker__day--disabled:hover {
  background-color: #bbbbbb;
}
.picker__footer {
  text-align: center;
}
.picker__footer button {
  border: 0;
  background: #fff;
  padding: 7px 14px;
  border-radius: 3px;
  font-weight: 500;
  cursor: pointer;
  display: inline-block;
}
.picker__footer button:hover,
.picker__footer button:focus {
  outline: 0;
  background-color: #03A9F5;
}
.picker__footer button:before {
  height: 0;
}
.picker__button--today:before {
  content: '';
  margin-right: 5px;
  position: relative;
  display: inline-block;
  top: -1px;
  width: 0;
  border-top: 6px solid #03A9F4;
  border-left: 6px solid transparent;
}
.picker__button--close:before {
  content: '\D7';
  display: inline-block;
  position: relative;
  margin-right: 5px;
  top: 1px;
  line-height: 1;
  font-size: 16px;
}
.picker__button--clear:before {
  content: '';
  display: inline-block;
  position: relative;
  top: -3px;
  width: 8px;
  margin-right: 5px;
  border-top: 2px solid #F44336;
}
/* ------------------------------------------------------------------------------
 *
 *  # Sweet alerts
 *
 *  Styles for sweet_alert.min.js - A beautiful replacement for JavaScript's "Alert"
 *
 *  Version: 1.1
 *  Latest update: Oct 28, 2015
 *
 * ---------------------------------------------------------------------------- */
/* # Core
-------------------------------------------------- */
body.stop-scrolling {
  height: 100%;
  overflow: hidden;
}
.sweet-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  z-index: 1050;
}
.sweet-alert {
  background-color: #fff;
  width: 470px;
  padding: 20px;
  border-radius: 3px;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -235px;
  margin-top: -200px;
  overflow: hidden;
  display: none;
  z-index: 1060;
  -webkit-box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.sweet-alert h2 {
  margin-top: 10px;
  font-size: 19px;
  text-align: center;
  display: block;
  position: relative;
}
.sweet-alert p {
  text-align: center;
  position: relative;
}
.sweet-alert fieldset {
  border: none;
  position: relative;
}
.sweet-alert button {
  background-color: #03A9F4;
  color: #fff;
  border: 0;
  border-radius: 3px;
  padding: 8px 15px;
  margin: 10px 5px 0 5px;
  box-shadow: none!important;
}
.sweet-alert button:hover {
  background-color: #29B6F6;
}
.sweet-alert button:focus {
  outline: 0;
}
.sweet-alert button:active {
  background-color: #03A9F4;
}
.sweet-alert button.cancel {
  background-color: transparent;
  color: #333333;
}
.sweet-alert button[disabled] {
  cursor: default;
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.sweet-alert button.confirm[disabled] {
  color: transparent;
}
.sweet-alert button.confirm[disabled] ~ .la-ball-fall {
  visibility: visible;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  opacity: 1;
  filter: alpha(opacity=100);
}
.sweet-alert button::-moz-focus-inner {
  border: 0;
}
.sweet-alert[data-has-cancel-button=false] button {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.sweet-alert[data-has-cancel-button=false][data-has-confirm-button=false] {
  padding-bottom: 40px;
}
.sweet-alert .sa-error-container {
  background-color: #03A9F5;
  overflow: hidden;
  padding: 0 10px;
  max-height: 0;
  border-radius: 3px;
  -webkit-transition: padding 0.15s, max-height 0.15s;
  -o-transition: padding 0.15s, max-height 0.15s;
  transition: padding 0.15s, max-height 0.15s;
}
.sweet-alert .sa-error-container p {
  display: inline-block;
  margin-bottom: 0;
}
.sweet-alert .sa-error-container.show {
  padding: 10px 0;
  max-height: 100px;
  -webkit-transition: padding 0.2s, max-height 0.2s;
  -o-transition: padding 0.2s, max-height 0.2s;
  transition: padding 0.2s, max-height 0.2s;
}
.sweet-alert .sa-error-container .icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  border-radius: 50%;
  background-color: #FF7043;
  color: white;
  text-align: center;
  margin-right: 8px;
}
.sweet-alert .sa-input-error {
  position: absolute;
  top: 20px;
  right: 16px;
  width: 16px;
  height: 16px;
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -o-transform: scale(0.5);
  transform: scale(0.5);
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
  opacity: 0;
  filter: alpha(opacity=0);
}
.sweet-alert .sa-input-error:before,
.sweet-alert .sa-input-error:after {
  content: "";
  width: 16px;
  height: 2px;
  background-color: #EF5350;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  margin-top: -1px;
  left: 50%;
  margin-left: -8px;
}
.sweet-alert .sa-input-error:before {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.sweet-alert .sa-input-error:after {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.sweet-alert .sa-input-error.show {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  filter: alpha(opacity=100);
}
.sweet-alert input {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #ddd;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 13px;
  padding: 8px 16px;
  display: none;
  -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05) inset;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05) inset;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.sweet-alert input::-moz-placeholder {
  color: #bdbdbd;
  opacity: 1;
}
.sweet-alert input:-ms-input-placeholder {
  color: #bdbdbd;
}
.sweet-alert input::-webkit-input-placeholder {
  color: #bdbdbd;
}
.sweet-alert input:focus {
  outline: 0;
}
.sweet-alert input:focus::-moz-placeholder,
.sweet-alert input:focus:-ms-input-placeholder,
.sweet-alert input:focus::-webkit-input-placeholder {
  -webkit-transition: opacity ease 0.3s 0.03s;
  -o-transition: opacity ease 0.3s 0.03s;
  transition: opacity ease 0.3s 0.03s;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.sweet-alert.show-input input {
  display: block;
}
.sweet-alert .sa-confirm-button-container {
  display: inline-block;
  position: relative;
}
.sweet-alert .la-ball-fall {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -27px;
  margin-top: 0;
  visibility: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
}
.sweet-alert .sa-icon {
  width: 80px;
  height: 80px;
  border: 4px solid #ddd;
  border-radius: 50%;
  margin: 10px auto 20px auto;
  padding: 0;
  position: relative;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.sweet-alert .sa-icon.sa-error {
  border-color: #F44336;
}
.sweet-alert .sa-icon.sa-error .sa-x-mark {
  position: relative;
  display: block;
}
.sweet-alert .sa-icon.sa-error .sa-line {
  position: absolute;
  height: 5px;
  width: 47px;
  background-color: #F44336;
  display: block;
  top: 37px;
  border-radius: 2px;
}
.sweet-alert .sa-icon.sa-error .sa-line.sa-left {
  left: 17px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.sweet-alert .sa-icon.sa-error .sa-line.sa-right {
  right: 16px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.sweet-alert .sa-icon.sa-warning {
  border-color: #FF5722;
}
.sweet-alert .sa-icon.sa-warning .sa-body {
  position: absolute;
  width: 5px;
  height: 47px;
  left: 50%;
  top: 10px;
  border-radius: 2px;
  margin-left: -2px;
  background-color: #FF5722;
}
.sweet-alert .sa-icon.sa-warning .sa-dot {
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-left: -3px;
  left: 50%;
  bottom: 10px;
  background-color: #FF5722;
}
.sweet-alert .sa-icon.sa-info {
  border-color: #03A9F4;
}
.sweet-alert .sa-icon.sa-info:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 29px;
  left: 50%;
  bottom: 17px;
  border-radius: 2px;
  margin-left: -2px;
  background-color: #03A9F4;
}
.sweet-alert .sa-icon.sa-info:after {
  content: "";
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-left: -3px;
  top: 19px;
  background-color: #03A9F4;
}
.sweet-alert .sa-icon.sa-success {
  border-color: #4CAF50;
}
.sweet-alert .sa-icon.sa-success:before,
.sweet-alert .sa-icon.sa-success:after {
  content: '';
  border-radius: 50%;
  position: absolute;
  width: 60px;
  height: 120px;
  background-color: #fff;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.sweet-alert .sa-icon.sa-success:before {
  border-radius: 120px 0 0 120px;
  top: -7px;
  left: -33px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 60px 60px;
  -moz-transform-origin: 60px 60px;
  -ms-transform-origin: 60px 60px;
  transform-origin: 60px 60px;
}
.sweet-alert .sa-icon.sa-success:after {
  border-radius: 0 120px 120px 0;
  top: -11px;
  left: 30px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0 60px;
  -moz-transform-origin: 0 60px;
  -ms-transform-origin: 0 60px;
  transform-origin: 0 60px;
}
.sweet-alert .sa-icon.sa-success .sa-placeholder {
  width: 80px;
  height: 80px;
  border: 4px solid rgba(76, 175, 80, 0.2);
  border-radius: 50%;
  position: absolute;
  left: -4px;
  top: -4px;
  z-index: 2;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.sweet-alert .sa-icon.sa-success .sa-fix {
  width: 5px;
  height: 90px;
  background-color: #fff;
  position: absolute;
  left: 28px;
  top: 8px;
  z-index: 1;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.sweet-alert .sa-icon.sa-success .sa-line {
  height: 5px;
  background-color: #4CAF50;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 2;
}
.sweet-alert .sa-icon.sa-success .sa-line.sa-tip {
  width: 25px;
  left: 14px;
  top: 46px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.sweet-alert .sa-icon.sa-success .sa-line.sa-long {
  width: 47px;
  right: 8px;
  top: 38px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.sweet-alert .sa-icon.sa-custom {
  background-size: contain;
  border-radius: 0;
  border: 0;
  background-position: center center;
  background-repeat: no-repeat;
}
@media (max-width: 480px) {
  .sweet-alert {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    left: 20px;
    right: 20px;
  }
}
/* # Animations
-------------------------------------------------- */
@-webkit-keyframes showSweetAlert {
  0% {
    -webkit-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -o-transform: scale(0.7);
    transform: scale(0.7);
  }
  45% {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
  }
  80% {
    -webkit-transform: scale(0.95);
    -ms-transform: scale(0.95);
    -o-transform: scale(0.95);
    transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes showSweetAlert {
  0% {
    -webkit-transform: scale(0.7);
    -ms-transform: scale(0.7);
    -o-transform: scale(0.7);
    transform: scale(0.7);
  }
  45% {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
  }
  80% {
    -webkit-transform: scale(0.95);
    -ms-transform: scale(0.95);
    -o-transform: scale(0.95);
    transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.showSweetAlert[data-animation=pop] {
  -webkit-animation: showSweetAlert 0.3s;
  -o-animation: showSweetAlert 0.3s;
  animation: showSweetAlert 0.3s;
}
.showSweetAlert[data-animation=none] {
  -webkit-animation: none;
  -o-animation: none;
  animation: none;
}
@-webkit-keyframes hideSweetAlert {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }
}
@keyframes hideSweetAlert {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }
}
.hideSweetAlert[data-animation=pop] {
  -webkit-animation: hideSweetAlert 0.2s;
  -o-animation: hideSweetAlert 0.2s;
  animation: hideSweetAlert 0.2s;
}
.hideSweetAlert[data-animation=none] {
  -webkit-animation: none;
  -o-animation: none;
  animation: none;
}
@-webkit-keyframes slideFromTop {
  0% {
    top: 0%;
  }
  100% {
    top: 50%;
  }
}
@keyframes slideFromTop {
  0% {
    top: 0%;
  }
  100% {
    top: 50%;
  }
}
.showSweetAlert[data-animation=slide-from-top] {
  -webkit-animation: slideFromTop 0.3s;
  -o-animation: slideFromTop 0.3s;
  animation: slideFromTop 0.3s;
}
@-webkit-keyframes slideToTop {
  0% {
    top: 50%;
  }
  100% {
    top: 0%;
  }
}
@keyframes slideToTop {
  0% {
    top: 50%;
  }
  100% {
    top: 0%;
  }
}
.hideSweetAlert[data-animation=slide-from-top] {
  -webkit-animation: slideToTop 0.4s;
  -o-animation: slideToTop 0.4s;
  animation: slideToTop 0.4s;
}
@-webkit-keyframes slideFromBottom {
  0% {
    top: 70%;
  }
  100% {
    top: 50%;
  }
}
@keyframes slideFromBottom {
  0% {
    top: 70%;
  }
  100% {
    top: 50%;
  }
}
.showSweetAlert[data-animation=slide-from-bottom] {
  -webkit-animation: slideFromBottom 0.3s;
  -o-animation: slideFromBottom 0.3s;
  animation: slideFromBottom 0.3s;
}
@-webkit-keyframes slideToBottom {
  0% {
    top: 50%;
  }
  100% {
    top: 70%;
  }
}
@keyframes slideToBottom {
  0% {
    top: 50%;
  }
  100% {
    top: 70%;
  }
}
.hideSweetAlert[data-animation=slide-from-bottom] {
  -webkit-animation: slideToBottom 0.3s;
  -o-animation: slideToBottom 0.3s;
  animation: slideToBottom 0.3s;
}
@-webkit-keyframes animateSuccessTip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}
@keyframes animateSuccessTip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}
.animateSuccessTip {
  -webkit-animation: animateSuccessTip 0.75s;
  -o-animation: animateSuccessTip 0.75s;
  animation: animateSuccessTip 0.75s;
}
@-webkit-keyframes animateSuccessLong {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}
@keyframes animateSuccessLong {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}
.animateSuccessLong {
  -webkit-animation: animateSuccessLong 0.75s;
  -o-animation: animateSuccessLong 0.75s;
  animation: animateSuccessLong 0.75s;
}
@-webkit-keyframes rotatePlaceholder {
  0% {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  5% {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  12% {
    -webkit-transform: rotate(-405deg);
    -ms-transform: rotate(-405deg);
    -o-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
  100% {
    -webkit-transform: rotate(-405deg);
    -ms-transform: rotate(-405deg);
    -o-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
}
@keyframes rotatePlaceholder {
  0% {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  5% {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  12% {
    -webkit-transform: rotate(-405deg);
    -ms-transform: rotate(-405deg);
    -o-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
  100% {
    -webkit-transform: rotate(-405deg);
    -ms-transform: rotate(-405deg);
    -o-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
}
.sa-icon.sa-success.animate::after {
  -webkit-animation: rotatePlaceholder 4.25s ease-in;
  -o-animation: rotatePlaceholder 4.25s ease-in;
  animation: rotatePlaceholder 4.25s ease-in;
}
@-webkit-keyframes animateErrorIcon {
  0% {
    -webkit-transform: rotateX(100deg);
    -ms-transform: rotateX(100deg);
    -o-transform: rotateX(100deg);
    transform: rotateX(100deg);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  100% {
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    transform: rotateX(0deg);
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
@keyframes animateErrorIcon {
  0% {
    -webkit-transform: rotateX(100deg);
    -ms-transform: rotateX(100deg);
    -o-transform: rotateX(100deg);
    transform: rotateX(100deg);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  100% {
    -webkit-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    -o-transform: rotateX(0deg);
    transform: rotateX(0deg);
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
.animateErrorIcon {
  -webkit-animation: animateErrorIcon 0.5s;
  -o-animation: animateErrorIcon 0.5s;
  animation: animateErrorIcon 0.5s;
}
@-webkit-keyframes animateXMark {
  0% {
    margin-top: 26px;
    -webkit-transform: scale(0.4);
    -ms-transform: scale(0.4);
    -o-transform: scale(0.4);
    transform: scale(0.4);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  50% {
    margin-top: 26px;
    -webkit-transform: scale(0.4);
    -ms-transform: scale(0.4);
    -o-transform: scale(0.4);
    transform: scale(0.4);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  80% {
    margin-top: -6px;
    -webkit-transform: scale(1.15);
    -ms-transform: scale(1.15);
    -o-transform: scale(1.15);
    transform: scale(1.15);
  }
  100% {
    margin-top: 0;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
@keyframes animateXMark {
  0% {
    margin-top: 26px;
    -webkit-transform: scale(0.4);
    -ms-transform: scale(0.4);
    -o-transform: scale(0.4);
    transform: scale(0.4);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  50% {
    margin-top: 26px;
    -webkit-transform: scale(0.4);
    -ms-transform: scale(0.4);
    -o-transform: scale(0.4);
    transform: scale(0.4);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  80% {
    margin-top: -6px;
    -webkit-transform: scale(1.15);
    -ms-transform: scale(1.15);
    -o-transform: scale(1.15);
    transform: scale(1.15);
  }
  100% {
    margin-top: 0;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
.animateXMark {
  -webkit-animation: animateXMark 0.5s;
  -o-animation: animateXMark 0.5s;
  animation: animateXMark 0.5s;
}
@-webkit-keyframes pulseWarning {
  0% {
    border-color: #F8D486;
  }
  100% {
    border-color: #F8BB86;
  }
}
@keyframes pulseWarning {
  0% {
    border-color: #F8D486;
  }
  100% {
    border-color: #F8BB86;
  }
}
.pulseWarning {
  -webkit-animation: pulseWarning 0.75s infinite alternate;
  -o-animation: pulseWarning 0.75s infinite alternate;
  animation: pulseWarning 0.75s infinite alternate;
}
@-webkit-keyframes pulseWarningIns {
  0% {
    background-color: #F8D486;
  }
  100% {
    background-color: #F8BB86;
  }
}
@keyframes pulseWarningIns {
  0% {
    background-color: #F8D486;
  }
  100% {
    background-color: #F8BB86;
  }
}
.pulseWarningIns {
  -webkit-animation: pulseWarningIns 0.75s infinite alternate;
  -o-animation: pulseWarningIns 0.75s infinite alternate;
  animation: pulseWarningIns 0.75s infinite alternate;
}
@-webkit-keyframes rotate-loading {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-loading {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.sweet-alert .sa-icon.sa-error .sa-line.sa-left {
  -ms-transform: rotate(45deg);
}
.sweet-alert .sa-icon.sa-error .sa-line.sa-right {
  -ms-transform: rotate(-45deg);
}
.sweet-alert .sa-icon.sa-success {
  border-color: transparent;
}
.sweet-alert .sa-icon.sa-success .sa-line.sa-tip {
  -ms-transform: rotate(45deg);
}
.sweet-alert .sa-icon.sa-success .sa-line.sa-long {
  -ms-transform: rotate(-45deg);
}
.la-ball-fall {
  display: block;
  font-size: 0;
  color: #fff;
  width: 54px;
  height: 12px;
}
.la-ball-fall.la-dark {
  color: #333333;
}
.la-ball-fall > div {
  display: inline-block;
  float: none;
  background-color: #fff;
  border: 0 solid #fff;
  width: 6px;
  height: 6px;
  margin: 2px;
  border-radius: 100%;
  -webkit-animation: ball-fall 1s ease-in-out infinite;
  -o-animation: ball-fall 1s ease-in-out infinite;
  animation: ball-fall 1s ease-in-out infinite;
  opacity: 0;
  filter: alpha(opacity=0);
}
.la-ball-fall > div:nth-child(1) {
  -webkit-animation-delay: -200ms;
  animation-delay: -200ms;
}
.la-ball-fall > div:nth-child(2) {
  -webkit-animation-delay: -100ms;
  animation-delay: -100ms;
}
.la-ball-fall > div:nth-child(3) {
  -webkit-animation-delay: 0ms;
  animation-delay: 0ms;
}
.la-ball-fall.la-sm {
  width: 26px;
  height: 8px;
}
.la-ball-fall.la-sm > div {
  width: 4px;
  height: 4px;
  margin: 2px;
}
.la-ball-fall.la-2x {
  width: 108px;
  height: 36px;
}
.la-ball-fall.la-2x > div {
  width: 20px;
  height: 20px;
  margin: 8px;
}
.la-ball-fall.la-3x {
  width: 162px;
  height: 54px;
}
.la-ball-fall.la-3x > div {
  width: 30px;
  height: 30px;
  margin: 12px;
}
@-webkit-keyframes ball-fall {
  0% {
    -webkit-transform: translate(0, -145%);
    -ms-transform: translate(0, -145%);
    -o-transform: translate(0, -145%);
    transform: translate(0, -145%);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  10% {
    opacity: 0.5;
    filter: alpha(opacity=50);
  }
  20% {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
    filter: alpha(opacity=100);
  }
  80% {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
    filter: alpha(opacity=100);
  }
  90% {
    opacity: 0.5;
    filter: alpha(opacity=50);
  }
  100% {
    -webkit-transform: translate(0, 145%);
    -ms-transform: translate(0, 145%);
    -o-transform: translate(0, 145%);
    transform: translate(0, 145%);
    opacity: 0;
    filter: alpha(opacity=0);
  }
}
@-moz-keyframes ball-fall {
  0% {
    -webkit-transform: translate(0, -145%);
    -ms-transform: translate(0, -145%);
    -o-transform: translate(0, -145%);
    transform: translate(0, -145%);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  10% {
    opacity: 0.5;
    filter: alpha(opacity=50);
  }
  20% {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
    filter: alpha(opacity=100);
  }
  80% {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
    filter: alpha(opacity=100);
  }
  90% {
    opacity: 0.5;
    filter: alpha(opacity=50);
  }
  100% {
    -webkit-transform: translate(0, 145%);
    -ms-transform: translate(0, 145%);
    -o-transform: translate(0, 145%);
    transform: translate(0, 145%);
    opacity: 0;
    filter: alpha(opacity=0);
  }
}
@-o-keyframes ball-fall {
  0% {
    -webkit-transform: translate(0, -145%);
    -ms-transform: translate(0, -145%);
    -o-transform: translate(0, -145%);
    transform: translate(0, -145%);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  10% {
    opacity: 0.5;
    filter: alpha(opacity=50);
  }
  20% {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
    filter: alpha(opacity=100);
  }
  80% {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
    filter: alpha(opacity=100);
  }
  90% {
    opacity: 0.5;
    filter: alpha(opacity=50);
  }
  100% {
    -webkit-transform: translate(0, 145%);
    -ms-transform: translate(0, 145%);
    -o-transform: translate(0, 145%);
    transform: translate(0, 145%);
    opacity: 0;
    filter: alpha(opacity=0);
  }
}
@keyframes ball-fall {
  0% {
    -webkit-transform: translate(0, -145%);
    -ms-transform: translate(0, -145%);
    -o-transform: translate(0, -145%);
    transform: translate(0, -145%);
    opacity: 0;
    filter: alpha(opacity=0);
  }
  10% {
    opacity: 0.5;
    filter: alpha(opacity=50);
  }
  20% {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
    filter: alpha(opacity=100);
  }
  80% {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
    filter: alpha(opacity=100);
  }
  90% {
    opacity: 0.5;
    filter: alpha(opacity=50);
  }
  100% {
    -webkit-transform: translate(0, 145%);
    -ms-transform: translate(0, 145%);
    -o-transform: translate(0, 145%);
    transform: translate(0, 145%);
    opacity: 0;
    filter: alpha(opacity=0);
  }
}
/* ------------------------------------------------------------------------------
 *
 *  # jQuery UI Interactions
 *
 *  Separate styles for jQuery UI library. Component's interactions
 *
 *  Version: 1.1
 *  Latest update: Mar 10, 2016
 *
 * ---------------------------------------------------------------------------- */
.ui-draggable-handle,
.ui-sortable-handle {
  -ms-touch-action: none;
  touch-action: none;
}
.ui-sortable .ui-state-disabled {
  color: #999999;
  cursor: not-allowed;
  opacity: 0.75;
  filter: alpha(opacity=75);
}
.sortable-placeholder {
  position: relative;
}
.sortable-placeholder:before {
  content: '';
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border: 1px dashed rgba(0, 0, 0, 0.2);
}
.sidebar .sortable-placeholder:before {
  border-left: 0;
  border-right: 0;
  background-color: rgba(0, 0, 0, 0.05);
}
.sidebar-category.ui-sortable-helper {
  background-color: rgba(0, 0, 0, 0.4);
}
.sidebar-default .sidebar-category.ui-sortable-helper {
  background-color: #fcfcfc;
}
.panel + .sortable-placeholder {
  margin-bottom: 20px;
}
.sortable-placeholder + .panel {
  margin-top: 20px;
}
.panel-group > .sortable-placeholder:before {
  border-radius: 3px;
}
.panel-group .panel + .sortable-placeholder {
  margin-top: 5px;
  margin-bottom: 5px;
}
.panel-group .sortable-placeholder + .panel {
  margin-top: 5px;
}
.table .ui-sortable-helper {
  width: 100%;
  background-color: #fff;
  display: table;
}
.table .sortable-placeholder {
  margin: 0;
}
.table .sortable-placeholder:before {
  content: none;
}
.table.ui-sortable {
  position: relative;
}
.ui-resizable {
  position: relative;
}
.ui-resizable,
.ui-resizable .ui-dialog-content {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.ui-resizable-handle {
  position: absolute;
  font-size: 0.1px;
  display: block;
  -ms-touch-action: none;
  touch-action: none;
}
.ui-resizable-handle.ui-icon {
  display: inline-block;
  border-style: solid;
  border-width: 0 0 6px 6px;
  border-color: transparent transparent #333333 transparent;
}
.ui-resizable-disabled .ui-resizable-handle,
.ui-resizable-autohide .ui-resizable-handle {
  display: none;
}
.ui-resizable-helper {
  border: 1px dashed #ccc;
}
.ui-resizable-n {
  cursor: n-resize;
  height: 7px;
  width: 100%;
  top: -5px;
  left: 0;
}
.ui-resizable-s {
  cursor: s-resize;
  height: 7px;
  width: 100%;
  bottom: -5px;
  left: 0;
}
.ui-resizable-e {
  cursor: e-resize;
  width: 7px;
  right: -5px;
  top: 0;
  height: 100%;
}
.ui-resizable-w {
  cursor: w-resize;
  width: 7px;
  left: -5px;
  top: 0;
  height: 100%;
}
.ui-resizable-se {
  cursor: se-resize;
  right: 1px;
  bottom: 1px;
}
.ui-resizable-sw {
  cursor: sw-resize;
  width: 9px;
  height: 9px;
  left: -5px;
  bottom: -5px;
}
.ui-resizable-nw {
  cursor: nw-resize;
  width: 9px;
  height: 9px;
  left: -5px;
  top: -5px;
}
.ui-resizable-ne {
  cursor: ne-resize;
  width: 9px;
  height: 9px;
  right: -5px;
  top: -5px;
}
.ui-selectable {
  -ms-touch-action: none;
  touch-action: none;
}
.ui-selectable-helper {
  position: absolute;
  z-index: 100;
  border: 1px dashed #333333;
}
/* ------------------------------------------------------------------------------
*
*  # Ripple effect
*
*  Material design ripple click effect
*
*  Version: 1.0
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.legitRipple {
  position: relative;
  overflow: hidden;
  z-index: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.legitRipple-ripple {
  position: absolute;
  width: 0;
  z-index: -1;
  pointer-events: none;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  will-change: transform, width, opacity;
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: width 0.2s linear, opacity 0.5s ease-out;
  -o-transition: width 0.2s linear, opacity 0.5s ease-out;
  transition: width 0.2s linear, opacity 0.5s ease-out;
}
.legitRipple-ripple:before {
  content: "";
  padding-top: 100%;
  display: block;
}
.breadcrumb-line[class*=bg-] .breadcrumb-elements > li .legitRipple-ripple {
  background-color: rgba(255, 255, 255, 0.1);
}
.btn-default .legitRipple-ripple,
.btn-link .legitRipple-ripple,
.btn-flat .legitRipple-ripple,
.icons-list > li > a .legitRipple-ripple,
.pagination > li:not(.active) > a .legitRipple-ripple,
.pagination > li:not(.active) > span .legitRipple-ripple,
.nav-tabs:not([class*=bg-]) > li > a .legitRipple-ripple,
.breadcrumb-elements > li .legitRipple-ripple,
.nav-pills > li > a .legitRipple-ripple,
.ui-button:not([class*=bg-]) .legitRipple-ripple,
.ui-tabs-anchor .legitRipple-ripple,
.fc-button .legitRipple-ripple,
.checkbox .legitRipple-ripple,
.wizard > .actions > ul > li:first-child > a .legitRipple-ripple,
.sidebar-default .navigation > li > a .legitRipple-ripple,
.sidebar-default .navigation > li ul li > a .legitRipple-ripple,
.navbar-default .navbar-nav > li > a .legitRipple-ripple {
  background-color: rgba(0, 0, 0, 0.05);
}
img ~ .legitRipple-ripple {
  z-index: auto;
}
.sidebar-user-material-content .legitRipple {
  border-radius: 50%;
}
.legitRipple-template {
  display: none;
}
.legitRipple-custom {
  overflow: hidden;
}
.legitRipple-custom > * {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
/* ------------------------------------------------------------------------------
 *
 *  # Bootstrap file input
 *
 *  File input styling for Bootstrap 3.0
 *
 *  Version: 1.3
 *  Latest update: Aug 10, 2016
 *
 * ---------------------------------------------------------------------------- */
.file-input.has-error .form-control {
  border-color: #ddd;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
@media (min-width: 769px) {
  .file-input .btn > [class*=icon-],
  .file-input .btn > .glyphicon {
    margin-right: 5px;
  }
}
.file-object {
  margin: 0 0 -8px 0;
  padding: 0;
}
.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  text-align: right;
  opacity: 0;
  background: none repeat scroll 0 0 transparent;
  cursor: inherit;
  display: block;
}
.file-caption-name {
  display: inline-block;
  overflow: hidden;
  word-break: break-all;
}
.file-caption-name [class*=icon-],
.file-caption-name .glyphicon {
  margin-right: 8px;
}
.file-input-new .file-caption-name {
  color: #999999;
}
.file-input-new .file-caption-name > [class*=icon-],
.file-input-new .file-caption-name > .glyphicon {
  display: none;
}
.file-error-message {
  position: relative;
  background-color: #f2dede;
  color: #a94442;
  text-align: center;
  border-radius: 3px;
  padding: 10px;
  margin: 0 5px 5px 5px;
}
.file-drop-zone .file-error-message {
  margin-top: 5px;
}
.file-error-message .close {
  margin-top: 1px;
  color: #a94442;
}
.file-error-message pre,
.file-error-message ul {
  margin: 8px 0;
  text-align: left;
}
.file-caption-disabled {
  cursor: not-allowed;
  border-bottom-style: dashed;
}
.file-caption-disabled:focus {
  border-bottom-color: #ddd;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.file-caption-disabled .file-caption-name {
  color: #ccc;
}
.file-preview-detail-modal {
  text-align: left;
}
.file-preview {
  border-radius: 2px;
  border: 1px solid #ddd;
  width: 100%;
  margin-bottom: 20px;
  position: relative;
  text-align: center;
}
.file-preview > .close {
  font-weight: 400;
  font-size: 17px;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  padding: 8px;
  line-height: 0.6;
  border-radius: 3px;
  color: #555;
  opacity: 1;
  filter: alpha(opacity=100);
}
.file-preview-frame {
  margin: 8px;
  margin-right: 0;
  height: 160px;
  display: table;
  float: left;
  vertical-align: middle;
}
.file-drop-zone .file-preview-frame {
  border: 1px solid #ddd;
  border-radius: 3px;
}
.file-preview-image {
  height: 160px;
  vertical-align: middle;
  border-radius: 3px;
}
@media (max-width: 768px) {
  .file-preview-image {
    max-width: 100%;
  }
}
.file-preview-text {
  text-align: left;
  width: 160px;
  margin-bottom: 2px;
  color: #428bca;
  background-color: #fff;
  overflow-x: hidden;
}
.file-preview-other {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 160px;
  height: 160px;
}
.file-preview-other:after {
  content: "\ea0c";
  font-family: "icomoon";
  display: block;
  font-size: 64px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.file-preview-other .glyphicon {
  display: none;
}
.file-preview-status {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #333333;
  background-color: rgba(250, 250, 250, 0.8);
  border-top: 1px solid #ddd;
  padding: 8px 16px;
  font-size: 12px;
  line-height: 1.6666667;
  color: #333333 !important;
}
.file-preview-status:empty {
  padding: 0;
  background-color: transparent;
  border: 0;
}
.file-thumb-loading {
  min-height: 109px;
}
.file-thumb-loading:before,
.file-uploading:before {
  content: "";
  display: inline-block;
  background-color: #263238;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -34.5px 0 0 -16px;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 5px rgba(255, 255, 255, 0.5);
  box-shadow: 0 1px 5px rgba(255, 255, 255, 0.5);
}
.file-thumb-loading:after,
.file-uploading:after {
  content: "\eb55";
  font-family: "icomoon";
  display: inline-block;
  position: absolute;
  color: #fff;
  top: 50%;
  left: 50%;
  margin: -26.5px 0 0 -8px;
  font-size: 16px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-animation: rotation 1s linear infinite;
  -o-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
}
.file-upload-indicator {
  padding-top: 7px;
  padding-bottom: 7px;
  cursor: default;
  text-align: left;
  padding-left: 9px;
}
.file-input-new .file-preview,
.file-input-new .close,
.file-input-new .glyphicon-file,
.file-input-new .fileinput-remove-button,
.file-input-new .fileinput-upload-button {
  display: none;
}
.file-input-new .input-group .input-group-btn > .btn-file {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
.file-input-ajax-new .fileinput-remove-button,
.file-input-ajax-new .fileinput-upload-button,
.file-input-ajax-new .fileinput-remove,
.file-input-ajax-new .file-caption-name > [class*=icon-],
.file-input-ajax-new .file-caption-name > .glyphicon {
  display: none;
}
.file-actions {
  margin-top: 5px;
  border-top: 1px solid #eeeeee;
}
.file-actions .btn > [class*=icon-],
.file-actions .btn > .glyphicon {
  margin-right: 0;
}
.file-footer-buttons {
  float: right;
}
.file-footer-caption {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 160px;
  text-align: center;
  font-size: 11px;
  padding: 12px 7px 7px 7px;
  margin-left: auto;
  margin-right: auto;
}
.file-preview-error {
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.file-drop-zone {
  border: 1px dashed #ddd;
  border-radius: 3px;
  height: 100%;
  vertical-align: middle;
  margin: 5px;
  padding: 5px;
}
.file-drop-zone-title {
  color: #999999;
  font-size: 21px;
  font-weight: 300;
  padding: 85px 10px;
}
.file-highlighted {
  border-color: #ccc;
  background-color: #fafafa;
}
.file-thumb-progress {
  position: absolute;
  top: -5px;
  left: 0;
  right: 0;
}
.file-thumb-progress .progress,
.file-thumb-progress .progress-bar {
  height: 5px;
  border-radius: 0;
  font-size: 0;
}
.file-thumbnail-footer {
  position: relative;
}
.btn-file ::-ms-browse {
  width: 100%;
  height: 100%;
}
.file-zoom-fullscreen.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.file-zoom-fullscreen .modal-dialog {
  position: fixed;
  margin: 0;
  width: 100%;
  height: 100%;
  padding: 0;
}
.file-zoom-fullscreen .modal-content {
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.file-zoom-fullscreen .modal-body {
  overflow-y: auto;
}
.file-zoom-dialog .btn-navigate {
  position: absolute;
  top: 50%;
  margin-top: -27px;
  padding: 0;
  border: 0;
  background: transparent;
  outline: none;
  color: #fff;
  opacity: 0.7;
  filter: alpha(opacity=70);
}
.file-zoom-dialog .btn-navigate > i {
  display: block;
  font-size: 32px;
}
.file-zoom-dialog .btn-navigate:not([disabled]):hover,
.file-zoom-dialog .btn-navigate:not([disabled]):focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.file-zoom-dialog .btn-navigate[disabled] {
  opacity: 0.3;
  filter: alpha(opacity=30);
}
.file-zoom-dialog .btn-prev {
  left: -42px;
}
.file-zoom-dialog .btn-next {
  right: -42px;
}
.file-zoom-dialog .floating-buttons {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #fff;
  border-radius: 0 0 0 3px;
}
.file-zoom-dialog .floating-buttons:not(:empty) {
  padding: 0 0 20px 20px;
}
.file-zoom-dialog .floating-buttons,
.file-zoom-dialog .floating-buttons .btn {
  z-index: 3000;
}
.kv-zoom-actions {
  position: absolute;
  top: 50%;
  right: 20px;
  margin-top: -5px;
}
.file-zoom-content {
  height: 480px;
  text-align: center;
}
.file-drag-handle {
  margin-right: 2px;
  float: left;
  cursor: move;
  cursor: -webkit-grabbing;
}
.file-drag-handle:hover {
  opacity: 0.7;
  filter: alpha(opacity=70);
}
/* ------------------------------------------------------------------------------
*
*  # Footable
*
*  jQuery plugin that aims to make HTML tables on smaller devices look awesome.
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.footable {
  width: 100%;
}
.footable > thead > tr > th {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.footable.breakpoint > tbody > tr.footable-detail-show > td {
  border-bottom: 0;
}
.footable.breakpoint > tbody > tr.footable-row-detail:hover {
  background-color: transparent;
}
.footable.breakpoint > tbody > tr:hover:not(.footable-row-detail) {
  cursor: pointer;
}
.footable.breakpoint > tbody > tr > .footable-cell-detail {
  background-color: #eeeeee;
  border-top: 0;
}
.footable.breakpoint > tbody > tr .footable-toggle:before {
  content: "\e9e4";
  display: inline-block;
  font-family: 'icomoon';
  font-size: 16px;
  vertical-align: middle;
  position: relative;
  top: -1px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-right: 10px;
}
.footable.breakpoint > tbody > tr.footable-detail-show .footable-toggle:before {
  content: "\e9e7";
}
.footable-row-detail-inner {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.footable-row-detail-row {
  display: table-row;
}
.footable-row-detail-group {
  display: block;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 8px;
}
.footable-row-detail-group:first-child {
  margin-top: 8px;
}
.footable-row-detail-name {
  display: table-cell;
  padding-right: 40px;
  font-weight: 500;
}
.footable-row-detail-value {
  display: table-cell;
  padding: 8px 0;
}
@media (max-width: 480px) {
  .footable-row-detail-inner {
    position: relative;
  }
  .footable-row-detail .dropdown,
  .footable-row-detail .dropup,
  .footable-row-detail .btn-group {
    position: static;
  }
  .footable-row-detail .dropdown-menu {
    left: 0;
    right: 0;
  }
}
/* ------------------------------------------------------------------------------
 *
 *  # Datatables library
 *
 *  Add advanced interaction controls to any HTML table
 *
 *  Version: 1.2
 *  Latest update: Mar 10, 2016
 *
 * ---------------------------------------------------------------------------- */
.dataTable {
  margin: 0;
  max-width: none;
  border-collapse: separate;
}
.dataTable thead th,
.dataTable thead td {
  outline: 0;
  position: relative;
}
.dataTable thead .sorting_asc,
.dataTable thead .sorting_desc,
.dataTable thead .sorting {
  cursor: pointer;
}
.dataTable thead .sorting,
.dataTable thead .sorting_asc,
.dataTable thead .sorting_desc,
.dataTable thead .sorting_asc_disabled,
.dataTable thead .sorting_desc_disabled {
  padding-right: 40px;
}
.dataTable thead .sorting:before,
.dataTable thead .sorting:after,
.dataTable thead .sorting_asc:after,
.dataTable thead .sorting_desc:after,
.dataTable thead .sorting_asc_disabled:after,
.dataTable thead .sorting_desc_disabled:after {
  content: '';
  font-family: 'icomoon';
  position: absolute;
  top: 50%;
  right: 20px;
  font-size: 12px;
  margin-top: -6px;
  display: inline-block;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.dataTable thead .sorting:before {
  content: '\e9c1';
  margin-top: -2px;
  color: #999999;
}
.dataTable thead .sorting:after {
  content: '\e9c2';
  margin-top: -10px;
  color: #999999;
}
.dataTable thead .sorting_asc:after {
  content: '\e9c2';
}
.dataTable thead .sorting_desc:after {
  content: '\e9c1';
}
.dataTable thead .sorting_asc_disabled:after {
  content: '\e9c2';
  color: #ccc;
}
.dataTable thead .sorting_desc_disabled:after {
  content: '\e9c1';
  color: #ccc;
}
.dataTable tbody > tr:first-child > th,
.dataTable tbody > tr:first-child > td {
  border-top: 0;
}
.dataTable tbody + thead > tr:first-child > th,
.dataTable tbody + tfoot + thead > tr:first-child > th,
.dataTable tbody + thead > tr:first-child > td,
.dataTable tbody + tfoot + thead > tr:first-child > td {
  border-top: 0;
}
.dataTable .dataTables_empty {
  text-align: center;
}
.dataTable.table-bordered {
  border-collapse: collapse;
}
.dataTables_wrapper {
  position: relative;
  clear: both;
}
.dataTables_wrapper:after {
  visibility: hidden;
  display: block;
  content: "";
  clear: both;
  height: 0;
}
.dataTables_wrapper .table-bordered {
  border-top: 0;
}
.dataTables_wrapper + .panel-body {
  border-top: 1px solid #ddd;
}
.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 40px;
  margin-left: -50%;
  margin-top: -25px;
  padding-top: 20px;
  text-align: center;
  background-color: #fff;
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(25%, rgba(255, 255, 255, 0.9)), color-stop(75%, rgba(255, 255, 255, 0.9)), color-stop(100%, rgba(255, 255, 255, 0)));
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
}
.datatable-header,
.datatable-footer {
  padding: 20px 20px 0 20px;
}
.datatable-header:after,
.datatable-footer:after {
  content: "";
  display: table;
  clear: both;
}
.datatable-header > div:first-child,
.datatable-footer > div:first-child {
  margin-left: 0;
}
.panel-body .datatable-header,
.panel-body .datatable-footer {
  padding-left: 0;
  padding-right: 0;
}
.datatable-header {
  border-bottom: 1px solid #ddd;
}
.datatable-footer {
  border-top: 1px solid #bbb;
}
.dataTables_length {
  float: right;
  display: inline-block;
  margin: 0 0 20px 20px;
}
.dataTables_length > label {
  margin-bottom: 0;
}
.dataTables_length > label > span:first-child {
  float: left;
  margin: 9px 20px;
  margin-left: 0;
}
.length-left .dataTables_length {
  float: left;
}
.dataTables_length .select2-container {
  width: auto;
}
.dataTables_length .select2-selection {
  min-width: 60px;
}
.dataTables_filter {
  position: relative;
  display: block;
  float: left;
  margin: 0 0 20px 20px;
}
.dataTables_filter > label {
  margin-bottom: 0;
  position: relative;
}
.dataTables_filter > label:after {
  content: "\e98e";
  font-family: 'icomoon';
  font-size: 12px;
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -6px;
  color: #999999;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.dataTables_filter > label > span {
  float: left;
  margin: 9px 20px;
  margin-left: 0;
}
.dataTables_filter input {
  outline: 0;
  width: 200px;
  height: 38px;
  padding: 8px 0;
  padding-right: 24px;
  font-size: 13px;
  line-height: 1.5384616;
  color: #333333;
  background-color: transparent;
  border: 1px solid transparent;
  border-width: 1px 0;
  border-bottom-color: #ddd;
}
.dataTables_filter input:focus {
  border-bottom-color: #03A9F4;
  -webkit-box-shadow: 0 1px 0 #03A9F4;
  box-shadow: 0 1px 0 #03A9F4;
}
.filter-right .dataTables_filter {
  float: right;
}
.dataTables_info {
  float: left;
  padding: 9px 0;
  margin-bottom: 20px;
}
.info-right .dataTables_info {
  float: right;
}
.dataTables_paginate {
  float: right;
  text-align: right;
  margin: 0 0 20px 20px;
}
.dataTables_paginate .paginate_button {
  display: inline-block;
  padding: 9px;
  min-width: 38px;
  margin-left: 2px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  color: #333333;
  border-radius: 3px;
}
.dataTables_paginate .paginate_button:first-child {
  margin-left: 0;
}
.dataTables_paginate .paginate_button:hover,
.dataTables_paginate .paginate_button:focus {
  background-color: #03A9F5;
}
.dataTables_paginate .paginate_button.current,
.dataTables_paginate .paginate_button.current:hover,
.dataTables_paginate .paginate_button.current:focus {
  color: #fff;
  background-color: #455A64;
}
.dataTables_paginate .paginate_button.disabled,
.dataTables_paginate .paginate_button.disabled:hover,
.dataTables_paginate .paginate_button.disabled:focus {
  cursor: default;
  background-color: transparent;
  color: #bbb;
}
.dataTables_paginate.paging_simple .paginate_button {
  padding-left: 16px;
  padding-right: 16px;
}
.paginate-left .dataTables_paginate {
  float: left;
}
.paging_simple .paginate_button:hover,
.paging_simple .paginate_button:focus {
  color: #fff;
  background-color: #455A64;
}
.dataTables_scroll {
  clear: both;
}
.dataTables_scroll .dataTables_scrollHead table {
  border-bottom: 0;
}
.dataTables_scroll .dataTables_scrollHead th,
.dataTables_scroll .dataTables_scrollHead td {
  white-space: nowrap;
}
.dataTables_scroll .dataTables_scrollBody {
  -webkit-overflow-scrolling: touch;
}
.dataTables_scroll .dataTables_scrollBody table {
  border-bottom: 0;
}
.dataTables_scroll .dataTables_scrollBody table thead th[class*=sorting]:before,
.dataTables_scroll .dataTables_scrollBody table thead th[class*=sorting]:after {
  content: none;
}
.dataTables_scroll .dataTables_scrollBody table tbody tr:first-child > td {
  border-top: 0;
}
.dataTables_scroll .dataTables_scrollBody th,
.dataTables_scroll .dataTables_scrollBody td {
  white-space: nowrap;
}
.dataTables_scroll .dataTables_scrollBody th > .dataTables_sizing,
.dataTables_scroll .dataTables_scrollBody td > .dataTables_sizing {
  height: 0;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.panel-body + .dataTables_wrapper,
.panel-body + * > .dataTables_wrapper {
  border-top: 1px solid #ddd;
}
.panel-body .dataTables_wrapper .datatable-footer {
  border-top: 0;
}
.panel-body .dataTables_wrapper .datatable-footer .dataTables_length,
.panel-body .dataTables_wrapper .datatable-footer .dataTables_filter,
.panel-body .dataTables_wrapper .datatable-footer .dataTables_info,
.panel-body .dataTables_wrapper .datatable-footer .dataTables_paginate {
  margin-bottom: 0;
}
.panel-flat > .panel-heading + .dataTables_wrapper > .datatable-header {
  padding-top: 0;
}
.panel > .dataTables_wrapper .table-bordered {
  border: 0;
}
.panel > .dataTables_wrapper .table-bordered > thead > tr > td:first-child,
.panel > .dataTables_wrapper .table-bordered > tbody > tr > td:first-child,
.panel > .dataTables_wrapper .table-bordered > tfoot > tr > td:first-child,
.panel > .dataTables_wrapper .table-bordered > thead > tr > th:first-child,
.panel > .dataTables_wrapper .table-bordered > tbody > tr > th:first-child,
.panel > .dataTables_wrapper .table-bordered > tfoot > tr > th:first-child {
  border-left: 0;
}
.panel > .dataTables_wrapper .table-bordered > thead > tr > td:last-child,
.panel > .dataTables_wrapper .table-bordered > tbody > tr > td:last-child,
.panel > .dataTables_wrapper .table-bordered > tfoot > tr > td:last-child,
.panel > .dataTables_wrapper .table-bordered > thead > tr > th:last-child,
.panel > .dataTables_wrapper .table-bordered > tbody > tr > th:last-child,
.panel > .dataTables_wrapper .table-bordered > tfoot > tr > th:last-child {
  border-right: 0;
}
.panel > .dataTables_wrapper .table-bordered > tbody > tr:last-child > th,
.panel > .dataTables_wrapper .table-bordered > tbody > tr:last-child > td {
  border-bottom: 0;
}
.datatable-scroll-lg,
.datatable-scroll,
.datatable-scroll-sm {
  min-height: .01%;
}
.datatable-scroll-wrap {
  width: 100%;
  min-height: .01%;
  overflow-x: auto;
}
@media (max-width: 768px) {
  .datatable-scroll-sm {
    width: 100%;
    overflow-x: scroll;
  }
  .datatable-scroll-sm th,
  .datatable-scroll-sm td {
    white-space: nowrap;
  }
}
@media (max-width: 1024px) {
  .datatable-scroll {
    width: 100%;
    overflow-x: scroll;
  }
  .datatable-scroll th,
  .datatable-scroll td {
    white-space: nowrap;
  }
}
@media (max-width: 1199px) {
  .datatable-scroll-lg {
    width: 100%;
    overflow-x: scroll;
  }
  .datatable-scroll-lg th,
  .datatable-scroll-lg td {
    white-space: nowrap;
  }
}
@media (max-width: 768px) {
  .dataTables_info,
  .dataTables_paginate,
  .dataTables_length,
  .dataTables_filter,
  .DTTT_container,
  .ColVis {
    float: none!important;
    text-align: center;
    margin-left: 0;
  }
  .dataTables_info,
  .dataTables_paginate {
    margin-top: 0;
  }
  .datatable-header {
    text-align: center;
  }
}
/* ------------------------------------------------------------------------------
*
*  # Responsive extension
*
*  Optimise the table's layout for different screen sizes through the dynamic 
*  insertion and removal of columns from the table
*
*  Version: 1.0
*  Latest update: May 25, 2015
*
* ---------------------------------------------------------------------------- */
.dtr-inline.collapsed tbody tr td:before,
.dtr-inline.collapsed tbody tr th:before {
  margin-right: 10px;
}
.dtr-inline.collapsed tbody tr td:first-child,
.dtr-inline.collapsed tbody tr th:first-child {
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}
.dtr-inline.collapsed tbody tr td:first-child.dataTables_empty:before,
.dtr-inline.collapsed tbody tr th:first-child.dataTables_empty:before {
  display: none;
}
.dtr-column tbody td.control,
.dtr-column tbody th.control {
  position: relative;
  cursor: pointer;
}
.dtr-inline.collapsed tbody tr td:first-child:before,
.dtr-inline.collapsed tbody tr th:first-child:before,
.dtr-column tbody tr td.control:before,
.dtr-column tbody tr th.control:before {
  content: '\e9e4';
  font-family: 'icomoon';
  display: inline-block;
  font-size: 16px;
  width: 16px;
  line-height: 1;
  position: relative;
  top: -1px;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.dataTable.dtr-inline.collapsed tbody tr.parent td:first-child:before,
.dataTable.dtr-inline.collapsed tbody tr.parent th:first-child:before,
.dataTable.dtr-column tbody tr.parent td.control:before,
.dataTable.dtr-column tbody tr.parent th.control:before {
  content: '\e9e7';
}
.dtr-inline.collapsed tbody tr.child td:before {
  display: none;
}
.dataTable tr.child:hover {
  background-color: transparent;
}
.dataTable tr.child .dtr-title {
  display: table-cell;
  font-weight: 500;
  padding-right: 40px;
}
.dataTable tr.child .dtr-data {
  display: table-cell;
  padding: 8px 0;
}
.dataTable tr td.child {
  white-space: normal;
  position: relative;
}
.dataTable tr td.child > ul {
  display: table;
  table-layout: fixed;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
}
.dataTable tr td.child > ul > li {
  display: table-row;
}
@media (max-width: 480px) {
  .dataTable tr td.child > ul > li .dropdown,
  .dataTable tr td.child > ul > li .dropup,
  .dataTable tr td.child > ul > li .btn-group {
    position: static;
  }
  .dataTable tr td.child > ul > li .dropdown-menu {
    left: 0;
    right: 0;
    border-radius: 0;
    border-width: 1px 0;
  }
}
/* ------------------------------------------------------------------------------
 *
 *  # Login and related forms
 *
 *  Styles related to user login - logins, registration, password revovery, unlock etc.
 *
 *  Version: 1.2
 *  Latest update: Mar 10, 2016
 *
 * ---------------------------------------------------------------------------- */
.login-cover {
  background: url('../images/login_cover.jpg') no-repeat;
  background-size: cover;
}
.login-container .page-container {
  padding-top: 40px;
  position: static;
}
.login-container .page-container .login-form {
  width: 320px;
}
.login-container .page-container .login-form .thumb {
  margin: 0 auto 20px auto;
}
.login-container .page-container .login-form,
.login-container .page-container .registration-form {
  margin: 0 auto 20px auto;
}
@media (max-width: 480px) {
  .login-container .page-container .login-form,
  .login-container .page-container .registration-form {
    width: 100%;
  }
}
.login-container .page-container .nav-tabs.nav-justified {
  margin-bottom: 0;
}
.login-container .page-container .nav-tabs.nav-justified > li > a {
  border-top: 0!important;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #03A9F5;
}
.login-container .page-container .nav-tabs.nav-justified > li:first-child > a {
  border-left: 0;
  border-radius: 3px 0 0 0;
}
.login-container .page-container .nav-tabs.nav-justified > li:last-child > a {
  border-right: 0;
  border-radius: 0 3px 0 0;
}
.login-container .page-container .nav-tabs.nav-justified > li.active > a {
  background-color: transparent;
}
@media (max-width: 768px) {
  .login-container .page-container .nav-tabs.nav-justified {
    padding: 0;
    border-width: 0 0 1px 0;
    border-radius: 0;
  }
  .login-container .page-container .nav-tabs.nav-justified:before {
    content: none;
  }
  .login-container .page-container .nav-tabs.nav-justified > li > a {
    border-width: 0!important;
  }
}
.login-container .footer {
  left: 0;
  right: 0;
}
@media (max-width: 768px) {
  .login-options,
  .login-options .text-right {
    text-align: center;
  }
}
/* ------------------------------------------------------------------------------
 *
 *  # Error pages
 *
 *  Styles for error and offline pages
 *
 *  Version: 1.0
 *  Latest update: May 25, 2015
 *
 * ---------------------------------------------------------------------------- */
.error-title {
  color: #fff;
  font-size: 200px;
  line-height: 1;
  margin-top: 20px;
  margin-bottom: 40px;
  font-weight: 300;
  text-stroke: 1px transparent;
  display: block;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2), 0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2), 0 20px 20px rgba(0, 0, 0, 0.15);
}
@media (max-width: 768px) {
  .error-title {
    font-size: 130px;
  }
}
.offline-title {
  font-size: 160px;
}
@media (max-width: 768px) {
  .offline-title {
    font-size: 90px;
  }
}
/* ------------------------------------------------------------------------------
*
*  # Heading elmeents
*
*  Display default and custom components in page header and panel heading
*
*  Version: 1.3
*  Latest update: Aug 10, 2016
*
* ---------------------------------------------------------------------------- */
.heading-elements-toggle {
  cursor: pointer;
  display: block;
  line-height: 1;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -8px;
}
.breadcrumb-elements-toggle {
  float: right;
  cursor: pointer;
  line-height: 1;
  margin-top: 12px;
}
.breadcrumb-line-component .breadcrumb-elements-toggle {
  margin-right: 20px;
}
.heading-elements-toggle,
.breadcrumb-elements-toggle,
.heading-elements-toggle:hover,
.breadcrumb-elements-toggle:hover,
.heading-elements-toggle:focus,
.breadcrumb-elements-toggle:focus {
  color: inherit;
}
.heading-elements-toggle > i,
.breadcrumb-elements-toggle > i {
  top: 0;
}
@media (min-width: 769px) {
  .heading-elements-toggle,
  .breadcrumb-elements-toggle {
    display: none;
  }
}
.heading-elements {
  background-color: inherit;
  position: absolute;
  top: 50%;
  right: 20px;
  height: 38px;
  margin-top: -19px;
}
@media (max-width: 768px) {
  .heading-elements:not(.not-collapsible) {
    position: static;
    margin-top: 0;
    height: auto;
  }
}
.panel-body > .heading-elements {
  top: 0;
  margin-top: 16px;
  z-index: 10;
}
@media (max-width: 768px) {
  .panel-body > .heading-elements-toggle {
    top: 20px;
    margin-top: 0;
  }
  .panel-body > .heading-elements.visible-elements {
    top: 56px;
    background-color: #fff;
  }
}
.panel-footer > .heading-elements {
  position: static;
  margin-top: 0;
  padding-right: 20px;
}
.panel-footer > .heading-elements:after {
  content: '';
  display: table;
  clear: both;
}
.panel-footer-bordered > .heading-elements:not(.visible-elements) {
  margin-left: -20px;
  padding-right: 0;
}
@media (max-width: 768px) {
  .panel-footer > .heading-elements:not(.not-collapsible) > .pull-right {
    float: none!important;
  }
  .panel-footer .heading-elements-toggle {
    position: static;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 0;
    display: block;
    text-align: center;
  }
}
.heading-thumbnails {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0;
}
.heading-thumbnails > li {
  position: relative;
  display: inline-block;
  font-size: 13px;
}
.heading-thumbnails > li + li {
  margin-left: 10px;
}
.heading-thumbnails > li img {
  height: auto;
  max-height: 38px;
  max-width: 100%;
  border-radius: 100px;
}
.heading-thumbnails > li .label,
.heading-thumbnails > li .badge {
  position: absolute;
  top: -8px;
  right: -8px;
  border: 2px solid #fcfcfc;
}
.panel-default > .panel-heading .heading-thumbnails > li .label,
.panel-default > .panel-heading .heading-thumbnails > li .badge,
.panel-footer-transparent .heading-thumbnails > li .label,
.panel-footer-transparent .heading-thumbnails > li .badge {
  border-color: #fff;
}
.heading-thumbnails > li .status-mark {
  position: absolute;
  top: 0;
  right: 0;
  box-shadow: 0 0 0 2px #fcfcfc;
}
.heading-thumbnails > li .status-mark[class*=border-] {
  background-color: #fcfcfc;
}
.panel-default > .panel-heading .heading-thumbnails > li .status-mark[class*=border-],
.panel-footer-transparent .heading-thumbnails > li .status-mark[class*=border-] {
  background-color: #fff;
}
.panel-default > .panel-heading .heading-thumbnails > li .status-mark,
.panel-footer-transparent .heading-thumbnails > li .status-mark {
  background-color: #fff;
}
.heading-elements .heading-btn + .heading-btn,
.page-header .heading-elements .icons-list > li + li,
.thumbnail-heading .heading-elements .icons-list > li + li {
  margin-left: 10px;
}
.page-header-inverse .heading-elements .btn-link {
  color: #fff;
}
.heading-elements .list-inline {
  margin-bottom: 0;
}
.heading-elements .breadcrumb {
  padding-top: 0;
  padding-bottom: 0;
}
.heading-elements.panel-nav .nav > li > a {
  text-align: left;
}
.heading-elements .daterange-custom {
  margin-top: 5px;
}
.heading-elements .heading-btn-group {
  font-size: 0;
}
.heading-elements .heading-btn-group > .btn + .btn:not(.btn-link) {
  margin-left: 10px;
}
.heading-elements.visible-elements {
  text-align: center;
}
.heading-elements.visible-elements .heading-text,
.heading-elements.visible-elements .heading-btn,
.heading-elements.visible-elements .heading-btn-group > .btn,
.heading-elements.visible-elements .ui-slider,
.heading-elements.visible-elements .noui-slider,
.heading-elements.visible-elements .nav-tabs,
.heading-elements.visible-elements .nav-pills,
.heading-elements.visible-elements .pagination,
.heading-elements.visible-elements .progress,
.heading-elements.visible-elements .icons-list,
.heading-elements.visible-elements .pager,
.heading-elements.visible-elements .breadcrumb,
.heading-elements.visible-elements .daterange-custom,
.heading-elements.visible-elements .heading-thumbnails,
.heading-elements.visible-elements .heading-form .form-group,
.heading-elements.visible-elements > .btn-group {
  margin-top: 20px;
}
.heading-elements.visible-elements .nav-tabs,
.heading-elements.visible-elements .nav-pills {
  margin-bottom: 0;
}
.heading-elements.visible-elements .heading-text:not(.label):not(.badge) {
  display: block;
}
.heading-elements.visible-elements .select2-container,
.heading-elements.visible-elements .selectboxit-container,
.heading-elements.visible-elements .selectboxit-options,
.heading-elements.visible-elements .multiselect + .btn-group,
.heading-elements.visible-elements div.bootstrap-select {
  width: 100%!important;
}
.heading-elements.visible-elements .input-group,
.heading-elements.visible-elements .input-group-btn,
.heading-elements.visible-elements .btn-group,
.heading-elements.visible-elements .dropdown,
.heading-elements.visible-elements .dropup {
  position: static;
}
.heading-elements.visible-elements .dropdown-menu:not(.multiselect-container) {
  left: -1px;
  right: -1px;
  margin-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.heading-elements.visible-elements .multiselect-container {
  left: 0;
  right: 0;
}
.heading-elements:not(.visible-elements) .heading-text,
.heading-elements:not(.visible-elements) .heading-btn,
.heading-elements:not(.visible-elements) .ui-slider,
.heading-elements:not(.visible-elements) .noui-slider,
.heading-elements:not(.visible-elements) .nav-tabs,
.heading-elements:not(.visible-elements) .nav-pills,
.heading-elements:not(.visible-elements) .pagination,
.heading-elements:not(.visible-elements) .progress,
.heading-elements:not(.visible-elements) .icons-list,
.heading-elements:not(.visible-elements) .breadcrumb,
.heading-elements:not(.visible-elements) .pager,
.heading-elements:not(.visible-elements) .heading-form,
.heading-elements:not(.visible-elements) .daterange-custom,
.heading-elements:not(.visible-elements) .heading-thumbnails,
.heading-elements:not(.visible-elements) > .btn-group {
  float: left;
  margin-left: 20px;
}
.heading-elements:not(.visible-elements) .heading-text {
  display: inline-block;
}
.heading-elements:not(.visible-elements) .heading-text + .heading-text {
  margin-left: 20px;
}
.heading-elements:not(.visible-elements) .selectbox-fixed + .selectboxit-container,
.heading-elements:not(.visible-elements) .selectbox-fixed + .selectboxit-options,
.heading-elements:not(.visible-elements) .progress,
.heading-elements:not(.visible-elements) .ui-slider,
.heading-elements:not(.visible-elements) .noui-slider,
.heading-elements:not(.visible-elements) .input-group,
.heading-elements:not(.visible-elements) .selectboxit-container .selectboxit-options,
.heading-elements:not(.visible-elements) .heading-form .form-control {
  width: 220px;
}
.heading-elements:not(.visible-elements) .select-sm,
.heading-elements:not(.visible-elements) .input-sm,
.heading-elements:not(.visible-elements) .input-group-sm,
.heading-elements:not(.visible-elements) .uploader-sm,
.heading-elements:not(.visible-elements) .pagination-sm,
.heading-elements:not(.visible-elements) .pager-sm,
.heading-elements:not(.visible-elements) .selectbox-sm + .selectboxit-container,
.heading-elements:not(.visible-elements) .btn-sm,
.heading-elements:not(.visible-elements) .btn-group-sm > .btn {
  margin-top: 1px;
}
.heading-elements:not(.visible-elements) .select-xs,
.heading-elements:not(.visible-elements) .input-xs,
.heading-elements:not(.visible-elements) .input-group-xs,
.heading-elements:not(.visible-elements) .uploader-xs,
.heading-elements:not(.visible-elements) .pagination-xs,
.heading-elements:not(.visible-elements) .pager-xs,
.heading-elements:not(.visible-elements) .selectbox-xs + .selectboxit-container,
.heading-elements:not(.visible-elements) .btn-xs,
.heading-elements:not(.visible-elements) .btn-group-xs > .btn {
  margin-top: 2px;
}
.heading-elements:not(.visible-elements) .btn-float {
  margin-top: -7px;
}
.heading-elements:not(.visible-elements) .btn-float.has-text {
  margin-top: -19.5px;
}
.heading-elements:not(.visible-elements) .btn-float.btn-link {
  margin-top: -14px;
}
.heading-elements:not(.visible-elements) .ui-slider,
.heading-elements:not(.visible-elements) .noui-slider {
  margin-top: 17px;
}
.heading-elements:not(.visible-elements) .ui-slider-lg,
.heading-elements:not(.visible-elements) .noui-slider-lg {
  margin-top: 16.5px;
}
.heading-elements:not(.visible-elements) .ui-slider-sm,
.heading-elements:not(.visible-elements) .noui-slider-sm {
  margin-top: 17.5px;
}
.heading-elements:not(.visible-elements) .ui-slider-xs,
.heading-elements:not(.visible-elements) .noui-slider-xs {
  margin-top: 18px;
}
.heading-elements:not(.visible-elements) .progress {
  margin-top: 10px;
}
.heading-elements:not(.visible-elements) .progress-lg {
  margin-top: 8px;
}
.heading-elements:not(.visible-elements) .progress-sm {
  margin-top: 12px;
}
.heading-elements:not(.visible-elements) .progress-xs {
  margin-top: 14px;
}
.heading-elements:not(.visible-elements) .progress-xxs {
  margin-top: 16px;
}
.heading-elements:not(.visible-elements) .progress-micro {
  margin-top: 18px;
}
.heading-elements:not(.visible-elements) .icons-list {
  margin-top: 11px;
}
.heading-elements:not(.visible-elements) .heading-text {
  margin-top: 9px;
}
.heading-elements:not(.visible-elements).panel-nav > .nav {
  margin-bottom: 0;
}
@media (min-width: 769px) {
  .heading-elements:not(.visible-elements).panel-nav > .nav {
    margin-top: -1px;
  }
  .heading-elements:not(.visible-elements).panel-nav > .nav-tabs {
    border-bottom-width: 0;
  }
  .heading-elements:not(.visible-elements).panel-nav > .nav-tabs.nav-tabs-bottom > li.active > a:after {
    background-color: transparent;
  }
  .heading-elements:not(.visible-elements).panel-nav > .nav-sm {
    margin-top: 2px;
  }
  .heading-elements:not(.visible-elements).panel-nav > .nav-xs {
    margin-top: 5px;
  }
}
@media (max-width: 768px) {
  .heading-elements:not(.visible-elements).panel-nav {
    position: static;
    height: auto;
  }
  .heading-elements:not(.visible-elements).panel-nav > .nav {
    float: none;
  }
  .panel-heading .heading-elements:not(.visible-elements).panel-nav {
    margin-top: 15px;
  }
  .panel-flat .panel-heading .heading-elements:not(.visible-elements).panel-nav {
    margin-top: 20px;
  }
  .panel-heading .heading-elements:not(.visible-elements).panel-nav > .nav {
    margin-left: 0;
  }
  .panel-footer .heading-elements:not(.visible-elements).panel-nav.not-collapsible > .nav {
    margin-top: 12px;
    margin-bottom: 12px;
  }
}
.heading-form .form-group {
  margin-bottom: 0;
}
.heading-form .checkbox-switchery,
.heading-form .checkbox-switchery[class*="switchery-"] {
  margin-bottom: 0;
}
.heading-elements:not(.visible-elements) .heading-form .checkbox-inline,
.heading-elements:not(.visible-elements) .heading-form .radio-inline {
  margin-top: 9px;
}
.heading-elements:not(.visible-elements) .heading-form .checkbox-switch {
  margin-top: 2px;
}
.heading-elements:not(.visible-elements) .heading-form .checkbox-switch-sm {
  margin-top: 3px;
}
.heading-elements:not(.visible-elements) .heading-form .checkbox-switch-xs {
  margin-top: 4px;
}
.heading-elements:not(.visible-elements) .heading-form .input-group {
  max-width: 220px;
}
.heading-elements:not(.visible-elements) .heading-form .input-group .form-control {
  width: 100%;
  margin-top: 0;
}
.heading-elements:not(.visible-elements) .heading-form .input-group.input-group-sm .btn,
.heading-elements:not(.visible-elements) .heading-form .input-group.input-group-xs .btn {
  margin-top: 0;
}
@media (min-width: 769px) {
  .heading-form .form-group {
    float: left;
  }
  .heading-form .form-group + .form-group {
    margin-left: 15px;
  }
}
@media (max-width: 768px) {
  .heading-elements:not(.not-collapsible),
  .breadcrumb-elements:not(.not-collapsible) {
    display: none;
  }
  .heading-elements.visible-elements,
  .breadcrumb-elements.visible-elements {
    display: block;
  }
  .panel .has-visible-elements {
    padding-bottom: 0;
  }
  .panel-flat .panel-heading.has-visible-elements {
    padding-bottom: 20px;
  }
  .panel .visible-elements {
    border-top: 1px solid #ddd;
    padding: 20px;
    padding-top: 0;
  }
  .panel-heading .visible-elements {
    margin: 15px -20px 0 -20px;
    background-color: #fcfcfc;
  }
  .panel-flat .panel-heading .visible-elements {
    margin-top: 20px;
    border-bottom: 1px solid #ddd;
  }
  .panel-footer .visible-elements {
    margin-top: 8px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .panel-footer-condensed .visible-elements {
    margin-top: 2px;
  }
  .panel-footer-transparent .visible-elements {
    margin-top: 11px;
    background-color: #fff;
  }
  .panel[class*=bg-] .visible-elements,
  .panel-heading[class*=bg-] .visible-elements,
  .panel-primary .visible-elements,
  .panel-danger .visible-elements,
  .panel-success .visible-elements,
  .panel-warning .visible-elements,
  .panel-info .visible-elements {
    border-color: rgba(255, 255, 255, 0.2);
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .visible-elements.breadcrumb-elements.not-collapsible {
    display: none;
  }
  .page-header-content > .visible-elements {
    background-color: #eeeded;
    left: 0;
    right: 0;
    border-top: 1px solid #e2e0e0;
    z-index: 1002;
    padding-bottom: 20px;
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .page-header-default .page-header-content > .visible-elements {
    background-color: #fcfcfc;
    border-color: #ddd;
  }
  .page-header-inverse .page-header-content > .visible-elements {
    background-color: rgba(0, 0, 0, 0.1);
    border-color: rgba(255, 255, 255, 0.1);
  }
}
/* ------------------------------------------------------------------------------
*
*  # Helper classes
*
*  Custom helper classes
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */
.no-edge-top {
  top: 0;
}
.no-edge-bottom {
  bottom: 0;
}
.no-edge-left {
  left: 0;
}
.no-edge-right {
  right: 0;
}
@media (min-width: 1200px) {
  .pull-right-lg {
    float: right;
  }
}
@media (min-width: 1025px) {
  .pull-right-md {
    float: right;
  }
}
@media (min-width: 769px) {
  .pull-right-sm {
    float: right;
  }
}
@media (min-width: 480px) {
  .pull-right-xs {
    float: right;
  }
}
.valign-top {
  vertical-align: top;
}
.valign-middle {
  vertical-align: middle;
}
.valign-bottom {
  vertical-align: bottom;
}
.valign-baseline {
  vertical-align: baseline;
}
.valign-text-top {
  vertical-align: top;
}
.valign-text-bottom {
  vertical-align: text-bottom;
}
.position-relative {
  position: relative;
}
.position-static {
  position: static;
}
.display-block,
label.display-block {
  display: block;
}
.display-inline-block {
  display: inline-block;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-move {
  cursor: move;
}
.cursor-default {
  cursor: default;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-visible {
  overflow: visible;
}
.overflow-auto {
  overflow: auto;
}
.content-group-xs {
  margin-bottom: 10px !important;
}
.content-group-sm {
  margin-bottom: 15px !important;
}
.content-group {
  margin-bottom: 20px !important;
}
.content-group-lg {
  margin-bottom: 30px !important;
}
.no-margin {
  margin: 0!important;
}
.no-margin-top {
  margin-top: 0!important;
}
.no-margin-bottom {
  margin-bottom: 0!important;
}
.no-margin-left {
  margin-left: 0!important;
}
.no-margin-right {
  margin-right: 0!important;
}
.m-5 {
  margin: 5px!important;
}
.m-10 {
  margin: 10px!important;
}
.m-15 {
  margin: 15px!important;
}
.m-20 {
  margin: 20px!important;
}
.mt-5 {
  margin-top: 5px!important;
}
.mt-10 {
  margin-top: 10px!important;
}
.mt-15 {
  margin-top: 15px!important;
}
.mt-20 {
  margin-top: 20px!important;
}
.mb-5 {
  margin-bottom: 5px!important;
}
.mb-10 {
  margin-bottom: 10px!important;
}
.mb-15 {
  margin-bottom: 15px!important;
}
.mb-20 {
  margin-bottom: 20px!important;
}
.ml-5 {
  margin-left: 5px!important;
}
.ml-10 {
  margin-left: 10px!important;
}
.ml-15 {
  margin-left: 15px!important;
}
.ml-20 {
  margin-left: 20px!important;
}
.mr-5 {
  margin-right: 5px!important;
}
.mr-10 {
  margin-right: 10px!important;
}
.mr-15 {
  margin-right: 15px!important;
}
.mr-20 {
  margin-right: 20px!important;
}
.no-padding {
  padding: 0!important;
}
.no-padding-top {
  padding-top: 0!important;
}
.no-padding-bottom {
  padding-bottom: 0!important;
}
.no-padding-left {
  padding-left: 0!important;
}
.no-padding-right {
  padding-right: 0!important;
}
.p-5 {
  padding: 5px!important;
}
.p-10 {
  padding: 10px!important;
}
.p-15 {
  padding: 15px!important;
}
.p-20 {
  padding: 20px!important;
}
.pt-5 {
  padding-top: 5px!important;
}
.pt-10 {
  padding-top: 10px!important;
}
.pt-15 {
  padding-top: 15px!important;
}
.pt-20 {
  padding-top: 20px!important;
}
.pb-5 {
  padding-bottom: 5px!important;
}
.pb-10 {
  padding-bottom: 10px!important;
}
.pb-15 {
  padding-bottom: 15px!important;
}
.pb-20 {
  padding-bottom: 20px!important;
}
.pl-5 {
  padding-left: 5px!important;
}
.pl-10 {
  padding-left: 10px!important;
}
.pl-15 {
  padding-left: 15px!important;
}
.pl-20 {
  padding-left: 20px!important;
}
.pr-5 {
  padding-right: 5px!important;
}
.pr-10 {
  padding-right: 10px!important;
}
.pr-15 {
  padding-right: 15px!important;
}
.pr-20 {
  padding-right: 20px!important;
}
.no-border {
  border: 0!important;
}
.no-border-top {
  border-top: 0!important;
}
.no-border-bottom {
  border-bottom: 0!important;
}
.no-border-left {
  border-left: 0!important;
}
.no-border-right {
  border-right: 0!important;
}
.no-border-radius {
  border-radius: 0;
}
.no-border-radius-top {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.no-border-radius-bottom {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.no-border-radius-left {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.no-border-radius-right {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.border-top {
  border-top: 1px solid;
}
.border-bottom {
  border-bottom: 1px solid;
}
.border-left {
  border-left: 1px solid;
}
.border-right {
  border-right: 1px solid;
}
.border-lg {
  border-width: 2px;
}
.border-top-lg {
  border-top: 2px solid;
}
.border-bottom-lg {
  border-bottom: 2px solid;
}
.border-left-lg {
  border-left: 2px solid;
}
.border-right-lg {
  border-right: 2px solid;
}
.border-xlg {
  border-width: 3px;
}
.border-top-xlg {
  border-top: 3px solid;
}
.border-bottom-xlg {
  border-bottom: 3px solid;
}
.border-left-xlg {
  border-left: 3px solid;
}
.border-right-xlg {
  border-right: 3px solid;
}
.no-shadow {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.shadow-depth1 {
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.shadow-depth2 {
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.shadow-depth3 {
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.shadow-depth4 {
  -webkit-box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.shadow-depth5 {
  -webkit-box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}
.full-width {
  width: 100%;
}
@media (min-width: 769px) {
  .width-200 {
    min-width: 200px;
  }
  .width-250 {
    min-width: 250px;
  }
  .width-300 {
    min-width: 300px;
  }
  .width-350 {
    min-width: 350px;
  }
  .width-400 {
    min-width: 400px;
  }
  .width-450 {
    min-width: 450px;
  }
  .width-500 {
    min-width: 500px;
  }
  .width-550 {
    min-width: 550px;
  }
  .width-600 {
    min-width: 600px;
  }
  .width-650 {
    min-width: 650px;
  }
}
.img-lg {
  width: 44px!important;
  height: 44px!important;
}
.img-sm {
  width: 36px!important;
  height: 36px!important;
}
.img-xs {
  width: 32px!important;
  height: 32px!important;
}
