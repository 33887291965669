/* ------------------------------------------------------------------------------
*
*  # Bootstrap tags input
*
*  Styles for tagsinput.js - tags input for Bootstrap
*
*  Version: 1.1
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */


// Core
// ------------------------------

.bootstrap-tagsinput {
    display: inline-block;
    vertical-align: middle;
    width: 100%;

    // Input field
    input {
        direction: ltr;
        border: 0;
        outline: 0;
        background-color: transparent;
        padding: @padding-small-vertical 0;
        margin-top: (@tags-spacing / 2);
        margin-bottom: (@tags-spacing / 2);
        width: auto !important;
        min-width: 100px;
        .box-shadow(none);

        // Remove focus interaction
        &:focus {
            border: none;
            box-shadow: none;
        }
    }

    // Twitter typeahead adaptation
    .twitter-typeahead {
        width: auto;
    }
    .tt-dropdown-menu {
        margin-top: 5px;
        min-width: 200px;
    }

    // Tag
    .tag {
        margin-top: (@tags-spacing / 2);
        margin-bottom: (@tags-spacing / 2);
        margin-right: @tags-spacing;
        border: 0;
        border-radius: 100px;
        padding: @padding-small-vertical @padding-small-horizontal;
        padding-right: ((@padding-small-horizontal * 1.5) + @font-size-small);
        float: left;
        font-size: @font-size-small;
        line-height: @line-height-small;
        font-weight: 400;
        text-transform: none;
        position: relative;

        // Skip tokens with custom background color
        &:not([class*=bg-]) {
            background-color: @color-primary-400;
            color: #fff;
        }

        // Remove button
        [data-role="remove"] {
            cursor: pointer;
            position: absolute;
            top: 50%;
            right: @padding-small-horizontal;
            line-height: 1;
            margin-top: -(@font-size-mini / 2);
            .opacity(0.7);

            // Hover interaction
            &:hover {
                .opacity(1);
            }

            // Icon
            &:after {
                content: '\ed6a';
                font-family: 'icomoon';
                display: block;
                font-size: @font-size-mini;
                color: #fff;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }
    }
}
