/* ------------------------------------------------------------------------------
*
*  # Ripple effect
*
*  Material design ripple click effect
*
*  Version: 1.0
*  Latest update: Mar 10, 2016
*
* ---------------------------------------------------------------------------- */


// Core
// -------------------------

// Base styles
.legitRipple {
    position: relative;
    overflow: hidden;
    z-index: 0;
    .user-select(none);
}

// Animation
.legitRipple-ripple {
    position: absolute;
    width: 0;
    z-index: -1;
    pointer-events: none;
    border-radius: 50%;
    background-color: fade(#fff, 10%);
    will-change: transform, width, opacity;
    .opacity(1);
    .translate3d(0, 0, 0);
    .translate(-50%, -50%);
    .transition(~"width 0.2s linear, opacity 0.5s ease-out");

    // Fix
    &:before {
        content: "";
        padding-top: 100%;
        display: block;
    }
}


//
// Ripple colors
//

// Light ripple overlay
.breadcrumb-line[class*=bg-] .breadcrumb-elements > li {
    .legitRipple-ripple {
        background-color: fade(#fff, 10%);
    }
}

// Dark ripple overlay
.btn-default,
.btn-link,
.btn-flat,
.icons-list > li > a,
.pagination > li:not(.active) > a,
.pagination > li:not(.active) > span,
.nav-tabs:not([class*=bg-]) > li > a,
.breadcrumb-elements > li,
.nav-pills > li > a,
.ui-button:not([class*=bg-]),
.ui-tabs-anchor,
.fc-button,
.checkbox,
.wizard > .actions > ul > li:first-child > a,
.sidebar-default .navigation > li > a,
.sidebar-default .navigation > li ul li > a,
.navbar-default .navbar-nav > li > a {
    .legitRipple-ripple {
        background-color: fade(#000, 5%);
    }
}


//
// Misc styles
//

// z-index fix
img ~ .legitRipple-ripple {
  z-index: auto;
}

// Round corners
.sidebar-user-material-content .legitRipple {
    border-radius: 50%;
}

// Template
.legitRipple-template {
  display: none;
}

// Custom ripple color
.legitRipple-custom {
  overflow: hidden;

    > * {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        .translate(-50%, -50%);
    }
}
